/*
Lists
--------------
@description: Base styles for list elements
-----------------------------------------------------------------------------*/
ul,
ol,
ul.default,
ol.default {
  margin-top: 0;
  margin-bottom: $small-spacing;
  padding-left: $base-spacing;
}

ul ul, ol ul {
  margin-bottom: 0;
  list-style-type: circle;
}

ol ol {
  margin-bottom: 0;
}

ol,
ol.default {
  list-style-type: decimal;
}

dl {
  margin-bottom: $small-spacing;

  dt {
    font-weight: bold;
    margin-top: $small-spacing;
  }

  dd {
    margin: 0;
  }
}
