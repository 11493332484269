// A clean way to deal with z-index layers in Sass
// Based on http://css-tricks.com/handling-z-index/
// ---

// ===================================================================================
// Layer Map
// -------------
// A map of z layers
// All z-index values should be set there

$z-layers: (
  'modal':            6000,
  'anchor':           5000,
  'nav':              4000,
  'default':             1,
  'bottomless-pit': -10000
) !default;

// ===================================================================================
// Z-Index function
// -------------
// A function helper to avoid having to type `map-get($z-layers, ...)`
// ---
// @param [string] $component: the layer to use
// ---
// @return [number] | [null]
@function z($layer) {
  @if not map-has-key($z-layers, $layer) {
    @warn "No z-index found in $z-layers map for `#{$layer}`. Property omitted.";
  }

  @return map-get($z-layers, $layer);
}