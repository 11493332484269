/*
Reset Styles
--------------
@description: Reset Styles to override default browser styles
-----------------------------------------------------------------------------*/

html {
  box-sizing: $box-sizing;  
  font-size: map-get($type-size, sm); // 16px

  @include media-breakpoint-up(md) {
    font-size: map-get($type-size, md); // 18px
  }

  @include media-breakpoint-up(lg) {
    font-size: map-get($type-size, lg); // 21px
  }
}

html,body {
  height: 100%;
  padding: 0;
  margin: 0;
}

body {
  @include body-copy;
  color: color(grey,dark);
  font-feature-settings: "kern", "liga", "pnum";
  text-size-adjust: 100%;
  text-size-adjust: 100%;
}

*, *:before, *:after {
  box-sizing: inherit;
}

img,
picture {
  margin: 0;
  max-width: 100%;
}
