/*
Paragraph
--------------
@description: Base styles for paragraph elements
-----------------------------------------------------------------------------*/

p,
.paragraph {
  @include body-copy;
  margin: 0 0 $base-spacing / 1.5;
  color: color(grey,dark);
  font-feature-settings: "kern", "liga", "pnum";
  text-size-adjust: 100%;
  text-size-adjust: 100%;
}

.lede {
  @include body-copy-large;
}
