/* 
Priorities Component
---------------------------
@description: 
------------------------------------------------*/

.Priorities {
  @include media-breakpoint-up(md) {
    @include make-row;
  }
}

.Priorities__item {
  margin: 0;

  @include media-breakpoint-up(md) {
    @include make-col();
    @include make-col-span(4);
  }
}

.Priorities__item__icon {
  width: 7.142857143rem;
  height: 7.142857143rem;
}

.Priorities__item__caption {
  margin-top: $base-spacing / 2;
}

.Priorities__item__caption .caption__headline {
  @include header-3;
  margin-bottom: $small-spacing;
  color: color(brand,green);
}

.Priorities__item__caption .caption__description {
  @include light-font;
  @include small;
}





