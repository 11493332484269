/*
Media object
--------------
@description: (http://www.stubbornella.org/content/2010/06/25/the-media-object-saves-hundreds-of-lines-of-code)
------------------------------------------------------------------------------------------------------------------*/

.media {
  @include clearfix;
  margin: $small-spacing 0;
}

.media-body {
  overflow:hidden; _overflow:visible; zoom:1;
}

.media-img {
  float:left;
  display: block;
  margin-right: 10px;
}

.media-img.img,
.media-img img {
  display:block;
}

.media .imgExt {
  float:right;
  margin-left: 10px;
}
