/* 
Twitter Component
---------------------------
@description: 
------------------------------------------------*/

.Twitter {
  @include clearfix;
  margin-bottom: $base-spacing / 2;
}
// Flexbox Support
.flexbox .Twitter {
  @include media-breakpoint-up(md) {
    display: flex;
    flex-wrap: wrap;
  }
}

/* Clear pseudo-elements to fix safri bug(da fuc safari????):
https://stackoverflow.com/questions/34250282/flexbox-safari-bug-flex-wrap
-------------------------------------------*/
.flexbox .Twitter:before,
.flexbox .Twitter:after {
  content: normal;
}

.Twitter__item {
  text-align: left;
  margin-bottom: $base-spacing / 2;

  @include media-breakpoint-up(md) {
    @include make-col;
    @include make-col-span(4);
    margin-bottom: 0;
  }
}
// Flexbox Support
.flexbox .Twitter__item {
  @include media-breakpoint-up(md) {
    display: flex;
  }
}

.Twitter__item__content {
  padding: $base-spacing / 2;
  background-color: color(base,light);
  box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.06);
}
// Flexbox Support
.flexbox .Twitter__item__content {
  @include media-breakpoint-up(md) {
    flex: 1;
  }
}

.Twitter__item__date {
  @include small;
  @include bold-font;
  margin-bottom: $small-spacing;
}

.Twitter__item__body {
  @include small;
}