/*
Links
--------------
@description: Base styles for link elements
-----------------------------------------------------------------------------*/

a {
  color: $user-action-color;
  text-decoration: none;
  transition: color $base-duration $base-timing;
}

a:hover,
a.hover {
    color: $user-action-color-hover;
    transition: color $base-duration $base-timing;
}

a:visited,
a.visited {
  // add a visited style to links, its good UX
  //color: $user-action-color-hover;
}

a:active,
a.active {

}

a:focus,
a.focus {

}



