/* 
Visibility utilities
---------------------------
@description: 
--------------------------------------------------------*/
.invisible {
  visibility: hidden !important;
}

// Responsive visibility utilities
@each $bp in map-keys($grid-breakpoints) {
  .hidden-#{$bp}-up {
    @include media-breakpoint-up($bp) {
      display: none !important;
    }
  }
  .hidden-#{$bp}-down {
    @include media-breakpoint-down($bp) {
      display: none !important;
    }
  }
}

/* 
Print utilities
---------------------------
Media queries are placed on the inside to be mixin-friendly.
--------------------------------------------------------*/
.visible-print-block {
  display: none !important;

  @media print {
    display: block !important;
  }
}
.visible-print-inline {
  display: none !important;

  @media print {
    display: inline !important;
  }
}
.visible-print-inline-block {
  display: none !important;

  @media print {
    display: inline-block !important;
  }
}

.hidden-print {
  @media print {
    display: none !important;
  }
}

/* 
Responsive utilities
---------------------------
More easily include all the states for responsive-utilities.less. 
[converter] $parent hack
--------------------------------------------------------*/
@mixin responsive-visibility($parent) {
  #{$parent} {
    display: block !important;
  }
  table#{$parent}  { display: table !important; }
  tr#{$parent}     { display: table-row !important; }
  th#{$parent},
  td#{$parent}     { display: table-cell !important; }
}

// [converter] $parent hack
@mixin responsive-invisibility($parent) {
  #{$parent} {
    display: none !important;
  }
}