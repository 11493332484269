/* 
Feature Block Component
---------------------------
@description: 
------------------------------------------------*/

// Local Variables
$feature-block-mobile-width: 100%;
$feature-block-width: 100%;
$feature-block-height: 85%;
$min-height-mobile: 554px;
$min-height-breakpoint-1: 25em;
$min-height-breakpoint-2: 640px;
$min-height-breakpoint-3: 768px;
$max-height: 42.857142857rem;
$feature-block-spacing: $base-spacing*4;
$feature-block-bg-color: #f5f5f5;
$feature-overlay-color: rgba(22, 22, 255, 0.7);

.FeatureBlock {
  position: relative;
  width: 100%;
  height: $feature-block-height;
  min-height: $min-height-mobile;
  background-color: $feature-block-bg-color;

  @include media-breakpoint-up(sm) {
    min-height: $min-height-breakpoint-1;
  }
  
  @include media-breakpoint-up(md) {
    min-height: $min-height-breakpoint-2;
  }

  @include media-breakpoint-up(lg) {
    min-height: $min-height-breakpoint-3;
  }
}

.featureBlock.imgBkg {
  background-image: url(http://www.fillmurray.com/g/1200/900);
  background-position: center;
  background-size: cover;
}

.FeatureBlock-videoBkg {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0;
}

.FeatureBlock-video {
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.featureBlock-wrapper {
  position: relative;
  width: 100%;
  height: 100%;

  &.color-overlay {
    background-color: $feature-overlay-color;
  }
}

.FeatureBlock-content {
  position: absolute;
  width: $feature-block-mobile-width;
  opacity: 0;
  transition: opacity $slowDuration $base-timing;

  @include media-breakpoint-up(sm) {
    width: $feature-block-width;
  }

  /* Postioning of Content:
  x, y:  
  c = centered
  t = top
  b = bottom
  l = left
  r = right
  */
  // Centered
  &.c-c {
    @include centerer(true, true);
    text-align: center;
  }
  //Centered Top
  &.c-t {
    margin-top: $feature-block-spacing;
    @include centerer(true, false);
  }
  // Centered Left
  &.c-l {
    margin-left: $feature-block-spacing;
    @include centerer(false, true);
  }
  // Centered Right
  &.c-r {
    right: 0%;
    margin-right: $feature-block-spacing;
    @include centerer(false, true);
  }
  // Left Top
  &.l-t {
    margin-top: $feature-block-spacing;
    margin-left: $feature-block-spacing;
  }
  // Left Bottom
  &.l-b {
    bottom: 0%;
    margin-bottom: $feature-block-spacing;
    margin-left: $feature-block-spacing;
  }
  // Right Top
  &.r-t {
    right: 0%;
    margin-top: $feature-block-spacing;
    margin-right: $feature-block-spacing;
  }
  // Right Bottom
  &.r-b {
    bottom: 0%;
    right: 0%;
    margin-bottom: $feature-block-spacing;
    margin-right: $feature-block-spacing;
  }
}

/*
Feature Block Down Arrow
----------------------------------------*/
.FeatureBlock__arrow {
  @include centerer(true,false)
  width: 20px;
  height: 20px;
  bottom: $base-spacing;
  opacity: 0;
  animation: arrow-pulse 2s infinite;
  transition: opacity $slowDuration $base-timing;
}

@keyframes arrow-pulse {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}

.top--0 .FeatureBlock__arrow {
  opacity: 1;
}

/*
Feature Block Title
----------------------------------------*/
.top--0 .FeatureBlock-content {
  opacity: 1;
}

.FeatureBlock__title {
  color: color(base,light);
}

.home .FeatureBlock__title {
  font-size: 1.875rem;
  
  @include media-breakpoint-up(sm) {
    font-size: 3rem;
    letter-spacing: -0.75px;
  }

  @include media-breakpoint-up(md) {
    font-size: 3.619047619rem;
  }
}

/*
Page Header
----------------------------------------*/
.FeatureBlock.FeatureBlock--page-header {
  background-color: color(brand,green);
  height: auto;
  min-height: unset;
}

.FeatureBlock.FeatureBlock--page-header .FeatureBlock__content {
  padding: 0 $base-spacing;
  text-align: center;
  
  @include media-breakpoint-up(md) {
    padding: 0 $section-spacing;
  }
}

.FeatureBlock.FeatureBlock--page-header .FeatureBlock__title {
  padding: $section-spacing * 1.25 0 $section-spacing / 1.5;
  max-width: $max-width;
  margin: 0 auto;
  opacity: 0;
  transition: opacity $slowDuration $base-timing;
  
  @include media-breakpoint-up(md) {
    padding: $section-spacing * 2 0 $section-spacing;
  }
}

.top--0 .FeatureBlock.FeatureBlock--page-header .FeatureBlock__title {
  opacity: 1;
}
