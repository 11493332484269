// ===================================================================================
//  Variables
// -------------

// -- Selectors
$all-buttons:     '.btn,
                   button,
                   input[type=submit],
                   input[type=button]' !default;

$ui-color-action: color(brand,green);

// -- Transition
$button-transition     : all $base-duration $base-timing;

// -- Border Radius
$button-borderRadius   : $base-border-radius; 

// -- Font
$button-font: (
  family               : $bold-font-family,
  weight               : $bold-font-family
);

// -- Text Size
$button-textSize: (
  small                : 0.761904762rem,
  default              : 0.80952381rem,
  large                : $base-font-size * 1.25
);

// -- Padding
$button-padding: (
  small                : $small-spacing / 2 $base-spacing / 2,
  default              : 0.80952381rem 0.80952381rem * 2,
  large                : $small-spacing $base-spacing,
);

// Colors:
// ------------
// Default Button
$default-button: (
  background        : transparent,
  outline           : $ui-color-action,
  color             : $ui-color-action,
  hover-background  : transparent,
  hover-color       : $ui-color-action
);
// Dark Button
$dark-button: (
  background        : $ui-color-action,
  outline           : $ui-color-action,
  color             : color(base,light),
  hover-background  : transparent,
  hover-outline     : color(brand,green),
  hover-color       : color(brand,green)
);
// Light Button
$light-button: (
  background        : color(base,light),
  outline           : color(base,light),
  color             : color(base,light),
  hover-background  : color(base,light),
  hover-color       : color(brand,green)
);


// ===================================================================================
//  Button Styles


// -- Default Button
.btn {
  display:             inline-block;
  appearance:          none;
  padding:             map-get($button-padding, default);
  background-color:    map-get($default-button, background);
  border:              2px solid;
  border-color:        map-get($default-button, background);
  color:               map-get($default-button, color);
  border-radius:       $button-borderRadius;
  cursor:              pointer;
  font-family:         map-get($button-font, family);
  font-size:           map-get($button-textSize, default);
  -webkit-font-smoothing: antialiased;
  font-weight:         map-get($button-font, weight);
  line-height:         1;
  text-decoration:     none;
  transition:          $button-transition;
  user-select:         none;
  vertical-align:      middle;
  white-space:         nowrap;
  text-transform:      uppercase;

  &:hover,
  &:focus {
    background-color:  map-get($default-button, hover-background);
    transition:        $button-transition;
    color:             map-get($default-button, hover-color);
  }

  &:disabled, 
  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;

    &:hover {
      background-color: $ui-color-action;
    }
  }

  &.small {
    font-size: map-get($button-textSize, small);
    padding: map-get($button-padding, small);
  }

  &.large {
    font-size: map-get($button-textSize, large);
    padding: map-get($button-padding, large);
  }
}

// -- Dark Button
.btn-dark {
  background-color:    map-get($dark-button, background);
  border-color:        map-get($dark-button, outline);
  color:               map-get($dark-button, color);

  &:hover {
    background-color:  map-get($dark-button, hover-background);
    border-color:      map-get($dark-button, hover-outline);
    color:             map-get($dark-button, hover-color);
  }

  &.outline {
    background-color: transparent;
    color:            map-get($dark-button, outline);
  }

  &:hover.outline {
    color:            map-get($dark-button, color);
    background-color: map-get($dark-button, background);
  }
}

// -- Light Button
.btn-light {
  background-color:    map-get($light-button, background);
  border-color:        map-get($light-button, background);
  color:               map-get($light-button, color);

  &:hover {
    background-color:  map-get($light-button, hover-background);
    border-color:      map-get($light-button, hover-background);
    color:             map-get($light-button, hover-color);
  }

  &.outline {
    background-color: transparent;
    color:            map-get($light-button, outline); 
  }

  &:hover.outline {
    background-color: map-get($light-button, hover-background);
    color:            map-get($light-button, hover-color); 
  }
}

.btn--mini {
  padding: 0.5rem 1rem;
  font-size: 0.5rem;
  letter-spacing: 1px;
}

.btn--mini--gr {
  padding: 0.5rem 1rem;
  font-size: 0.5rem;
  letter-spacing: 1px;
  background-color: color(grey,dark);
  border-color: color(grey,dark);

  &:hover {
    color: color(grey,dark);
    border-color: color(grey,dark);
  }
}
