/* 
Partner List Component
---------------------------
@description: 
------------------------------------------------*/

.PartnerList {
  margin-bottom: $base-spacing;
  padding-left: 0;
  font-size: 0.857142857rem;
  line-height: 1.45;
  list-style: none;
  columns: 1;

  @include media-breakpoint-up(md) {
    columns: 2;
    column-gap: $base-spacing;
    text-align: left;
  }

  @include media-breakpoint-up(lg) {
    columns: 3;
  }
}

.PartnerList.PartnerList--small {
  @include small;
}


.PartnerList__item {
  margin-bottom: $small-spacing / 2;
}

