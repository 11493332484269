/* 
Section Block Component
---------------------------
@description: 
------------------------------------------------*/

.SectionBlock {
  padding: $section-spacing / 2 0;

  @include media-breakpoint-up(md) {
    padding: $section-spacing 0;
  }
}

.SectionBlock__content  {
  @include make-container();
}

.SectionBlock__icon {
  width: 1.904761905rem;
  margin-bottom: $small-spacing;
}

.SectionBlock__title {
  margin-bottom: $base-spacing / 1.5;
  color: color(grey,dark);
  letter-spacing: 1px;
}

.SectionBlock--alternate:nth-child(even) {
  background-color: color(grey,light);

  .SectionBlock__title  {
    color: color(base,dark);
  }
}

.SectionBlock--imgBkg {
  background-position: center;
  background-size: cover;
}

.SectionBlock.SectionBlock--imgBkg .SectionBlock__title {
  color: color(base,light);
}

/*
Partners Section
-------------------------------------------*/
.SectionBlock.SectionBlock--partners {
  padding-top: 0;
}

