/* 
Updates Component
---------------------------
@description: 
------------------------------------------------*/

.Updates {
  @include make-container;
  margin-bottom: $small-spacing;

  @include media-breakpoint-up(md) {
    margin-bottom: $base-spacing / 2;
  }
}

.Updates__item {
  text-align: left;
  margin-bottom: $small-spacing;

  @include media-breakpoint-up(md) {
    @include make-col;
    @include make-col-span(4);
    margin-bottom: 0;
  }
}

.Updates__item__date {
  @include bold-font;
  @include small;
  margin-bottom: $small-spacing;
}

.Updates__item__link {
  display: block;
  color: color(grey,dark);

  &:hover {
    color: color(brand,green);
  }
}

.Updates__item__title {
  @include light-font;
}

.Updates__item__btn {

}