/* 
Typography
---------------------------
@description: 
--------------------------------------------------------*/

/* 
Alignment
----------------------------*/
.text-center         { text-align: center !important; }
.text-justify        { text-align: justify !important; }
.text-nowrap         { white-space: nowrap !important; }
.text-truncate       { white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }

/* 
Responsive alignment
----------------------------*/
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    .text-#{$breakpoint}-left   { text-align: left !important; }
    .text-#{$breakpoint}-right  { text-align: right !important; }
    .text-#{$breakpoint}-center { text-align: center !important; }
  }
}

/* 
Transformation
----------------------------*/
.text-lowercase      { text-transform: lowercase !important; }
.text-uppercase      { text-transform: uppercase !important; }
.text-capitalize     { text-transform: capitalize !important; }

/* 
Weight and italics
----------------------------*/
.font-weight-normal  { font-weight: normal; }
.font-weight-bold    { font-weight: bold; }
.font-italic         { font-style: italic; }

/* 
Underline
----------------------------*/
.underline           { text-decoration: underline !important; }

/* 
Strike through
----------------------------*/
.strike              {text-decoration: line-through !important;}

/* 
Break Word & No Wrap
----------------------------*/
.force-break         { word-wrap: break-word !important; }
.nowrap              { white-space: nowrap !important; }
.wrap                { white-space: normal !important; }
.word-break          { word-break: break-all !important; }

/* 
Contextual colors
----------------------------*/
/*
@include text-emphasis-variant('.text-primary', $brand-primary);
@include text-emphasis-variant('.text-success', $brand-success);
@include text-emphasis-variant('.text-info', $brand-info);
@include text-emphasis-variant('.text-warning', $brand-warning);
@include text-emphasis-variant('.text-danger', $brand-danger);
*/
