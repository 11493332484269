@charset "UTF-8";
/*
Import All SCSS
=============================================*/
/*---------------------------------------------
Import Variables
---------------------------------------------*/
/* 
Color Pallette. 
Tints available as a function 
*/
/*
Sizing & Spacing.
All layout spacing and ui-sizing should be built on top of this variable map
*/
/*
Typography.
All typography should be built on top of this variable map
*/
/*
Sizing & Spacing.
All layout spacing and ui-sizing should be built on top of this variable map
*/
/* 
Grid Settings.
These are used 
*/
/* 
Breakpoints.  
If grid classes are enabled, each breakpoint generated about 5kb of css 
*/
/*
Animation.
Use these settings to keep overall animation feeling consistent.  
*/
/*---------------------------------------------
Import Tools
---------------------------------------------*/
/*
Tools
------------------------------*/
/* Fool-proof @font-face */
/* Based on http://coding.smashingmagazine.com/2013/02/14/setting-weights-and-styles-at-font-face-declaration/ */
/* See here: http://codepen.io/javasteve99/pen/hDxpn?editors=110 */
.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12 {
    position: relative;
    min-height: 1px;
    padding-left: 0.7381rem;
    padding-right: 0.7381rem;
}

.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
    float: left;
}

.col-xs-1 {
    width: 8.33333%;
}

.col-xs-2 {
    width: 16.66667%;
}

.col-xs-3 {
    width: 25%;
}

.col-xs-4 {
    width: 33.33333%;
}

.col-xs-5 {
    width: 41.66667%;
}

.col-xs-6 {
    width: 50%;
}

.col-xs-7 {
    width: 58.33333%;
}

.col-xs-8 {
    width: 66.66667%;
}

.col-xs-9 {
    width: 75%;
}

.col-xs-10 {
    width: 83.33333%;
}

.col-xs-11 {
    width: 91.66667%;
}

.col-xs-12 {
    width: 100%;
}

.col-xs-pull-0 {
    right: auto;
}

.col-xs-pull-1 {
    right: 8.33333%;
}

.col-xs-pull-2 {
    right: 16.66667%;
}

.col-xs-pull-3 {
    right: 25%;
}

.col-xs-pull-4 {
    right: 33.33333%;
}

.col-xs-pull-5 {
    right: 41.66667%;
}

.col-xs-pull-6 {
    right: 50%;
}

.col-xs-pull-7 {
    right: 58.33333%;
}

.col-xs-pull-8 {
    right: 66.66667%;
}

.col-xs-pull-9 {
    right: 75%;
}

.col-xs-pull-10 {
    right: 83.33333%;
}

.col-xs-pull-11 {
    right: 91.66667%;
}

.col-xs-pull-12 {
    right: 100%;
}

.col-xs-push-0 {
    left: auto;
}

.col-xs-push-1 {
    left: 8.33333%;
}

.col-xs-push-2 {
    left: 16.66667%;
}

.col-xs-push-3 {
    left: 25%;
}

.col-xs-push-4 {
    left: 33.33333%;
}

.col-xs-push-5 {
    left: 41.66667%;
}

.col-xs-push-6 {
    left: 50%;
}

.col-xs-push-7 {
    left: 58.33333%;
}

.col-xs-push-8 {
    left: 66.66667%;
}

.col-xs-push-9 {
    left: 75%;
}

.col-xs-push-10 {
    left: 83.33333%;
}

.col-xs-push-11 {
    left: 91.66667%;
}

.col-xs-push-12 {
    left: 100%;
}

.col-xs-offset-0 {
    margin-left: 0%;
}

.col-xs-offset-1 {
    margin-left: 8.33333%;
}

.col-xs-offset-2 {
    margin-left: 16.66667%;
}

.col-xs-offset-3 {
    margin-left: 25%;
}

.col-xs-offset-4 {
    margin-left: 33.33333%;
}

.col-xs-offset-5 {
    margin-left: 41.66667%;
}

.col-xs-offset-6 {
    margin-left: 50%;
}

.col-xs-offset-7 {
    margin-left: 58.33333%;
}

.col-xs-offset-8 {
    margin-left: 66.66667%;
}

.col-xs-offset-9 {
    margin-left: 75%;
}

.col-xs-offset-10 {
    margin-left: 83.33333%;
}

.col-xs-offset-11 {
    margin-left: 91.66667%;
}

.col-xs-offset-12 {
    margin-left: 100%;
}

@media (min-width: 34em) {
    .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
        float: left;
    }

    .col-sm-1 {
        width: 8.33333%;
    }

    .col-sm-2 {
        width: 16.66667%;
    }

    .col-sm-3 {
        width: 25%;
    }

    .col-sm-4 {
        width: 33.33333%;
    }

    .col-sm-5 {
        width: 41.66667%;
    }

    .col-sm-6 {
        width: 50%;
    }

    .col-sm-7 {
        width: 58.33333%;
    }

    .col-sm-8 {
        width: 66.66667%;
    }

    .col-sm-9 {
        width: 75%;
    }

    .col-sm-10 {
        width: 83.33333%;
    }

    .col-sm-11 {
        width: 91.66667%;
    }

    .col-sm-12 {
        width: 100%;
    }

    .col-sm-pull-0 {
        right: auto;
    }

    .col-sm-pull-1 {
        right: 8.33333%;
    }

    .col-sm-pull-2 {
        right: 16.66667%;
    }

    .col-sm-pull-3 {
        right: 25%;
    }

    .col-sm-pull-4 {
        right: 33.33333%;
    }

    .col-sm-pull-5 {
        right: 41.66667%;
    }

    .col-sm-pull-6 {
        right: 50%;
    }

    .col-sm-pull-7 {
        right: 58.33333%;
    }

    .col-sm-pull-8 {
        right: 66.66667%;
    }

    .col-sm-pull-9 {
        right: 75%;
    }

    .col-sm-pull-10 {
        right: 83.33333%;
    }

    .col-sm-pull-11 {
        right: 91.66667%;
    }

    .col-sm-pull-12 {
        right: 100%;
    }

    .col-sm-push-0 {
        left: auto;
    }

    .col-sm-push-1 {
        left: 8.33333%;
    }

    .col-sm-push-2 {
        left: 16.66667%;
    }

    .col-sm-push-3 {
        left: 25%;
    }

    .col-sm-push-4 {
        left: 33.33333%;
    }

    .col-sm-push-5 {
        left: 41.66667%;
    }

    .col-sm-push-6 {
        left: 50%;
    }

    .col-sm-push-7 {
        left: 58.33333%;
    }

    .col-sm-push-8 {
        left: 66.66667%;
    }

    .col-sm-push-9 {
        left: 75%;
    }

    .col-sm-push-10 {
        left: 83.33333%;
    }

    .col-sm-push-11 {
        left: 91.66667%;
    }

    .col-sm-push-12 {
        left: 100%;
    }

    .col-sm-offset-0 {
        margin-left: 0%;
    }

    .col-sm-offset-1 {
        margin-left: 8.33333%;
    }

    .col-sm-offset-2 {
        margin-left: 16.66667%;
    }

    .col-sm-offset-3 {
        margin-left: 25%;
    }

    .col-sm-offset-4 {
        margin-left: 33.33333%;
    }

    .col-sm-offset-5 {
        margin-left: 41.66667%;
    }

    .col-sm-offset-6 {
        margin-left: 50%;
    }

    .col-sm-offset-7 {
        margin-left: 58.33333%;
    }

    .col-sm-offset-8 {
        margin-left: 66.66667%;
    }

    .col-sm-offset-9 {
        margin-left: 75%;
    }

    .col-sm-offset-10 {
        margin-left: 83.33333%;
    }

    .col-sm-offset-11 {
        margin-left: 91.66667%;
    }

    .col-sm-offset-12 {
        margin-left: 100%;
    }
}

@media (min-width: 48em) {
    .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
        float: left;
    }

    .col-md-1 {
        width: 8.33333%;
    }

    .col-md-2 {
        width: 16.66667%;
    }

    .col-md-3 {
        width: 25%;
    }

    .col-md-4 {
        width: 33.33333%;
    }

    .col-md-5 {
        width: 41.66667%;
    }

    .col-md-6 {
        width: 50%;
    }

    .col-md-7 {
        width: 58.33333%;
    }

    .col-md-8 {
        width: 66.66667%;
    }

    .col-md-9 {
        width: 75%;
    }

    .col-md-10 {
        width: 83.33333%;
    }

    .col-md-11 {
        width: 91.66667%;
    }

    .col-md-12 {
        width: 100%;
    }

    .col-md-pull-0 {
        right: auto;
    }

    .col-md-pull-1 {
        right: 8.33333%;
    }

    .col-md-pull-2 {
        right: 16.66667%;
    }

    .col-md-pull-3 {
        right: 25%;
    }

    .col-md-pull-4 {
        right: 33.33333%;
    }

    .col-md-pull-5 {
        right: 41.66667%;
    }

    .col-md-pull-6 {
        right: 50%;
    }

    .col-md-pull-7 {
        right: 58.33333%;
    }

    .col-md-pull-8 {
        right: 66.66667%;
    }

    .col-md-pull-9 {
        right: 75%;
    }

    .col-md-pull-10 {
        right: 83.33333%;
    }

    .col-md-pull-11 {
        right: 91.66667%;
    }

    .col-md-pull-12 {
        right: 100%;
    }

    .col-md-push-0 {
        left: auto;
    }

    .col-md-push-1 {
        left: 8.33333%;
    }

    .col-md-push-2 {
        left: 16.66667%;
    }

    .col-md-push-3 {
        left: 25%;
    }

    .col-md-push-4 {
        left: 33.33333%;
    }

    .col-md-push-5 {
        left: 41.66667%;
    }

    .col-md-push-6 {
        left: 50%;
    }

    .col-md-push-7 {
        left: 58.33333%;
    }

    .col-md-push-8 {
        left: 66.66667%;
    }

    .col-md-push-9 {
        left: 75%;
    }

    .col-md-push-10 {
        left: 83.33333%;
    }

    .col-md-push-11 {
        left: 91.66667%;
    }

    .col-md-push-12 {
        left: 100%;
    }

    .col-md-offset-0 {
        margin-left: 0%;
    }

    .col-md-offset-1 {
        margin-left: 8.33333%;
    }

    .col-md-offset-2 {
        margin-left: 16.66667%;
    }

    .col-md-offset-3 {
        margin-left: 25%;
    }

    .col-md-offset-4 {
        margin-left: 33.33333%;
    }

    .col-md-offset-5 {
        margin-left: 41.66667%;
    }

    .col-md-offset-6 {
        margin-left: 50%;
    }

    .col-md-offset-7 {
        margin-left: 58.33333%;
    }

    .col-md-offset-8 {
        margin-left: 66.66667%;
    }

    .col-md-offset-9 {
        margin-left: 75%;
    }

    .col-md-offset-10 {
        margin-left: 83.33333%;
    }

    .col-md-offset-11 {
        margin-left: 91.66667%;
    }

    .col-md-offset-12 {
        margin-left: 100%;
    }
}

@media (min-width: 62em) {
    .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
        float: left;
    }

    .col-lg-1 {
        width: 8.33333%;
    }

    .col-lg-2 {
        width: 16.66667%;
    }

    .col-lg-3 {
        width: 25%;
    }

    .col-lg-4 {
        width: 33.33333%;
    }

    .col-lg-5 {
        width: 41.66667%;
    }

    .col-lg-6 {
        width: 50%;
    }

    .col-lg-7 {
        width: 58.33333%;
    }

    .col-lg-8 {
        width: 66.66667%;
    }

    .col-lg-9 {
        width: 75%;
    }

    .col-lg-10 {
        width: 83.33333%;
    }

    .col-lg-11 {
        width: 91.66667%;
    }

    .col-lg-12 {
        width: 100%;
    }

    .col-lg-pull-0 {
        right: auto;
    }

    .col-lg-pull-1 {
        right: 8.33333%;
    }

    .col-lg-pull-2 {
        right: 16.66667%;
    }

    .col-lg-pull-3 {
        right: 25%;
    }

    .col-lg-pull-4 {
        right: 33.33333%;
    }

    .col-lg-pull-5 {
        right: 41.66667%;
    }

    .col-lg-pull-6 {
        right: 50%;
    }

    .col-lg-pull-7 {
        right: 58.33333%;
    }

    .col-lg-pull-8 {
        right: 66.66667%;
    }

    .col-lg-pull-9 {
        right: 75%;
    }

    .col-lg-pull-10 {
        right: 83.33333%;
    }

    .col-lg-pull-11 {
        right: 91.66667%;
    }

    .col-lg-pull-12 {
        right: 100%;
    }

    .col-lg-push-0 {
        left: auto;
    }

    .col-lg-push-1 {
        left: 8.33333%;
    }

    .col-lg-push-2 {
        left: 16.66667%;
    }

    .col-lg-push-3 {
        left: 25%;
    }

    .col-lg-push-4 {
        left: 33.33333%;
    }

    .col-lg-push-5 {
        left: 41.66667%;
    }

    .col-lg-push-6 {
        left: 50%;
    }

    .col-lg-push-7 {
        left: 58.33333%;
    }

    .col-lg-push-8 {
        left: 66.66667%;
    }

    .col-lg-push-9 {
        left: 75%;
    }

    .col-lg-push-10 {
        left: 83.33333%;
    }

    .col-lg-push-11 {
        left: 91.66667%;
    }

    .col-lg-push-12 {
        left: 100%;
    }

    .col-lg-offset-0 {
        margin-left: 0%;
    }

    .col-lg-offset-1 {
        margin-left: 8.33333%;
    }

    .col-lg-offset-2 {
        margin-left: 16.66667%;
    }

    .col-lg-offset-3 {
        margin-left: 25%;
    }

    .col-lg-offset-4 {
        margin-left: 33.33333%;
    }

    .col-lg-offset-5 {
        margin-left: 41.66667%;
    }

    .col-lg-offset-6 {
        margin-left: 50%;
    }

    .col-lg-offset-7 {
        margin-left: 58.33333%;
    }

    .col-lg-offset-8 {
        margin-left: 66.66667%;
    }

    .col-lg-offset-9 {
        margin-left: 75%;
    }

    .col-lg-offset-10 {
        margin-left: 83.33333%;
    }

    .col-lg-offset-11 {
        margin-left: 91.66667%;
    }

    .col-lg-offset-12 {
        margin-left: 100%;
    }
}

@media (min-width: 75em) {
    .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12 {
        float: left;
    }

    .col-xl-1 {
        width: 8.33333%;
    }

    .col-xl-2 {
        width: 16.66667%;
    }

    .col-xl-3 {
        width: 25%;
    }

    .col-xl-4 {
        width: 33.33333%;
    }

    .col-xl-5 {
        width: 41.66667%;
    }

    .col-xl-6 {
        width: 50%;
    }

    .col-xl-7 {
        width: 58.33333%;
    }

    .col-xl-8 {
        width: 66.66667%;
    }

    .col-xl-9 {
        width: 75%;
    }

    .col-xl-10 {
        width: 83.33333%;
    }

    .col-xl-11 {
        width: 91.66667%;
    }

    .col-xl-12 {
        width: 100%;
    }

    .col-xl-pull-0 {
        right: auto;
    }

    .col-xl-pull-1 {
        right: 8.33333%;
    }

    .col-xl-pull-2 {
        right: 16.66667%;
    }

    .col-xl-pull-3 {
        right: 25%;
    }

    .col-xl-pull-4 {
        right: 33.33333%;
    }

    .col-xl-pull-5 {
        right: 41.66667%;
    }

    .col-xl-pull-6 {
        right: 50%;
    }

    .col-xl-pull-7 {
        right: 58.33333%;
    }

    .col-xl-pull-8 {
        right: 66.66667%;
    }

    .col-xl-pull-9 {
        right: 75%;
    }

    .col-xl-pull-10 {
        right: 83.33333%;
    }

    .col-xl-pull-11 {
        right: 91.66667%;
    }

    .col-xl-pull-12 {
        right: 100%;
    }

    .col-xl-push-0 {
        left: auto;
    }

    .col-xl-push-1 {
        left: 8.33333%;
    }

    .col-xl-push-2 {
        left: 16.66667%;
    }

    .col-xl-push-3 {
        left: 25%;
    }

    .col-xl-push-4 {
        left: 33.33333%;
    }

    .col-xl-push-5 {
        left: 41.66667%;
    }

    .col-xl-push-6 {
        left: 50%;
    }

    .col-xl-push-7 {
        left: 58.33333%;
    }

    .col-xl-push-8 {
        left: 66.66667%;
    }

    .col-xl-push-9 {
        left: 75%;
    }

    .col-xl-push-10 {
        left: 83.33333%;
    }

    .col-xl-push-11 {
        left: 91.66667%;
    }

    .col-xl-push-12 {
        left: 100%;
    }

    .col-xl-offset-0 {
        margin-left: 0%;
    }

    .col-xl-offset-1 {
        margin-left: 8.33333%;
    }

    .col-xl-offset-2 {
        margin-left: 16.66667%;
    }

    .col-xl-offset-3 {
        margin-left: 25%;
    }

    .col-xl-offset-4 {
        margin-left: 33.33333%;
    }

    .col-xl-offset-5 {
        margin-left: 41.66667%;
    }

    .col-xl-offset-6 {
        margin-left: 50%;
    }

    .col-xl-offset-7 {
        margin-left: 58.33333%;
    }

    .col-xl-offset-8 {
        margin-left: 66.66667%;
    }

    .col-xl-offset-9 {
        margin-left: 75%;
    }

    .col-xl-offset-10 {
        margin-left: 83.33333%;
    }

    .col-xl-offset-11 {
        margin-left: 91.66667%;
    }

    .col-xl-offset-12 {
        margin-left: 100%;
    }
}

/* Good design relies on the consistent & intelligent use of a few type styles.
   Define this core set of type styles below, and use them with purpose throughout
   this interactive product.

   It may be a good idea to avoid including color in these mixins. Color largely depends on
   state, and therefore should be flexible (eg: link vs. title, active vs. visited, success vs. warning etc).
   For some projects though, you should include color below.  You'll need to use your expertise
   as a designer.
*/
/*
Font Style Mixins
------------------------------------------*/
/*---------------------------------------------
Import Base
---------------------------------------------*/
/*
Base styles
--------------------
Reset, normalisation, and raw tag styles. Outside of these
files, all CSS selectors should be class names.
------------------------------------------------------------------*/
/*
Reset Styles
--------------
@description: Reset Styles to override default browser styles
-----------------------------------------------------------------------------*/
html {
  box-sizing: border-box;
  font-size: 16px; }
  @media (min-width: 48em) {
    html {
      font-size: 18px; } }
  @media (min-width: 62em) {
    html {
      font-size: 21px; } }

html, body {
  height: 100%;
  padding: 0;
  margin: 0; }

body {
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.58;
  color: #95989A;
  font-feature-settings: "kern", "liga", "pnum";
  text-size-adjust: 100%;
  text-size-adjust: 100%; }

*, *:before, *:after {
  box-sizing: inherit; }

img,
picture {
  margin: 0;
  max-width: 100%; }

/* 
//=====
name: "Icon Font";
slug: base/icons
description: [
  Icon font
];  
=====//
 */
@font-face {
  font-family: "farmbill-icons";
  src: url("../fonts/icons/farmbill-icons.eot");
  src: url("../fonts/icons/farmbill-icons.eot?#iefix") format("eot"), url("../fonts/icons/farmbill-icons.woff") format("woff"), url("../fonts/icons/farmbill-icons.ttf") format("truetype"), url("../fonts/icons/farmbill-icons.svg#farmbill-icons") format("svg"); }

.icon--arrow-up:before, .icon--check:before, .icon--close-circle:before, .icon--close:before, .icon--dollar:before, .icon--edit:before, .icon--menu:before, .icon--minus-circle:before, .icon--navigate-down-circle:before, .icon--navigate-down:before, .icon--navigate-up:before, .icon--plus:before, .icon--preview:before, .icon--renew:before, .icon--search:before, .icon--settings:before, .icon--user:before {
  font-family: "farmbill-icons";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none; }

.icon--arrow-up:before {
  content: ""; }

.icon--check:before {
  content: ""; }

.icon--close-circle:before {
  content: ""; }

.icon--close:before {
  content: ""; }

.icon--dollar:before {
  content: ""; }

.icon--edit:before {
  content: ""; }

.icon--menu:before {
  content: ""; }

.icon--minus-circle:before {
  content: ""; }

.icon--navigate-down-circle:before {
  content: ""; }

.icon--navigate-down:before {
  content: ""; }

.icon--navigate-up:before {
  content: ""; }

.icon--plus:before {
  content: ""; }

.icon--preview:before {
  content: ""; }

.icon--renew:before {
  content: ""; }

.icon--search:before {
  content: ""; }

.icon--settings:before {
  content: ""; }

.icon--user:before {
  content: ""; }

/*
Grid Containers
--------------
@description: These are the main wrappers for content that contain the grid
-----------------------------------------------------------------------------*/
/*
Default Containers
--------------
Set the container width, and override it for fixed navbars in media queries.
-----------------------------------------------------------------------------*/
.container {
  max-width: 1150px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 1.47619rem;
  padding-right: 1.47619rem;
  max-width: 1150px;
  position: relative; }
  .container {
    *zoom: 1; }
  .container:before, .container:after {
    content: "";
    display: table; }
  .container:after {
    clear: both; }

.row {
  margin-left: -0.7381rem;
  margin-right: -0.7381rem; }
  .row {
    *zoom: 1; }
  .row:before, .row:after {
    content: "";
    display: table; }
  .row:after {
    clear: both; }

.container.no-gutter {
  max-width: 1150px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 0;
  padding-right: 0; }
  .container.no-gutter {
    *zoom: 1; }
  .container.no-gutter:before, .container.no-gutter:after {
    content: "";
    display: table; }
  .container.no-gutter:after {
    clear: both; }

.container.alternate:nth-child(even),
.row.alternate:nth-child(even) {
  background-color: #eee; }

/*
Flex variation
--------------
Custom styles for additional flex alignment options.
------------------------------------------------------------*/
/*
Font Styles
--------------
@description: Base typography styles
-----------------------------------------------------------------------------*/
/*
Paragraph
--------------
@description: Base styles for paragraph elements
-----------------------------------------------------------------------------*/
p,
.paragraph {
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.58;
  margin: 0 0 1.96825rem;
  color: #95989A;
  font-feature-settings: "kern", "liga", "pnum";
  text-size-adjust: 100%;
  text-size-adjust: 100%; }

.lede {
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-size: 1.333333333rem;
  font-weight: 300;
  line-height: 1.58; }

/*
Headings
--------------
@description: Base styles for heading elements
-----------------------------------------------------------------------------*/
h1, .h1 {
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-size: 2.857142857rem;
  font-weight: 700;
  line-height: 1.2;
  margin: 0 0 1rem; }

h2, .h2 {
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-size: 1.523809524rem;
  font-weight: 700;
  line-height: 1.4;
  margin: 0 0 1rem; }

h3, .h3 {
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-size: 1rem;
  font-weight: 900;
  line-height: 1.2;
  margin: 0 0 1rem; }

h4, h5, h6 {
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-size: 1rem;
  font-weight: 900;
  line-height: 1.2;
  margin: 0 0 1rem; }

/* Section Labels are primarily used as titles on each homepage section  */
.sectionLabel {
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-size: 0.761904762rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 1rem; }

/*
Links
--------------
@description: Base styles for link elements
-----------------------------------------------------------------------------*/
a {
  color: #127F3F;
  text-decoration: none;
  transition: color 250ms ease-out; }

a:hover,
a.hover {
  color: #127F3F;
  transition: color 250ms ease-out; }

/*
Lists
--------------
@description: Base styles for list elements
-----------------------------------------------------------------------------*/
ul,
ol,
ul.default,
ol.default {
  margin-top: 0;
  margin-bottom: 1rem;
  padding-left: 2.95238rem; }

ul ul, ol ul {
  margin-bottom: 0;
  list-style-type: circle; }

ol ol {
  margin-bottom: 0; }

ol,
ol.default {
  list-style-type: decimal; }

dl {
  margin-bottom: 1rem; }
  dl dt {
    font-weight: bold;
    margin-top: 1rem; }
  dl dd {
    margin: 0; }

/*
Tables
--------------
@description: Base styles for table elements
-----------------------------------------------------------------------------*/
table {
  border-collapse: collapse;
  font-feature-settings: "kern", "liga", "tnum";
  margin: 1rem 0;
  table-layout: fixed;
  width: 100%; }

th {
  border-bottom: 1px solid shade(#000000, 25%);
  font-weight: 600;
  padding: 1rem 0;
  text-align: left; }

td {
  border-bottom: 1px solid #000000;
  padding: 1rem 0; }

tr,
td,
th {
  vertical-align: middle; }

/*
Rules
--------------
@description: base styles for rule elements
-----------------------------------------------------------------------------*/
hr,
.hr {
  border-bottom: 1px solid #000000;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  margin: 2.95238rem 0; }

.vertical-rule {
  border-left: 1px solid #000000;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  margin: 0 2.95238rem; }

/*
Flag object
--------------
@description: Modified version of Harry Roberts' flag object
(http://csswizardry.com/2013/05/the-flag-object/)

Similar to the media object. Used to vertically align a fluid-width element
and a fixed-width element next to one another.
-----------------------------------------------------------------------------*/
.flag {
    display: table;
    margin: 1rem 0;
    width: 100%;
}

.flag-image,
.flag-body {
    display: table-cell;
    vertical-align: middle;
}

.flag-image.top,
  .flag-body.top {
    vertical-align: top;
}

.flag-image.bottom,
  .flag-body.bottom {
    vertical-align: bottom;
}

.flag-image {
    padding-right: 10px;
}

.flag-image > img {
    display: block;
    max-width: none;
}

.flag-image.reverse {
    padding-right: 0;
    padding-left: 10px;
}

.flag-body {
    width: 100%;
}

/*
Media object
--------------
@description: (http://www.stubbornella.org/content/2010/06/25/the-media-object-saves-hundreds-of-lines-of-code)
------------------------------------------------------------------------------------------------------------------*/
.media {
    margin: 1rem 0;
}

.media {
    *zoom: 1;
}

.media:before, .media:after {
    content: "";
    display: table;
}

.media:after {
    clear: both;
}

.media-body {
    overflow: hidden;
    _overflow: visible;
    zoom: 1;
}

.media-img {
    float: left;
    display: block;
    margin-right: 10px;
}

.media-img.img,
.media-img img {
    display: block;
}

.media .imgExt {
    float: right;
    margin-left: 10px;
}

.btn {
    display: inline-block;
    appearance: none;
    padding: 0.80952rem 1.61905rem;
    background-color: transparent;
    border: 2px solid;
    border-color: transparent;
    color: #127F3F;
    border-radius: 0;
    cursor: pointer;
    font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
    font-size: 0.80952rem;
    -webkit-font-smoothing: antialiased;
    font-weight: "Montserrat", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
    line-height: 1;
    text-decoration: none;
    transition: all 250ms ease-out;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
    text-transform: uppercase;
}

.btn:hover, .btn:focus {
    background-color: transparent;
    transition: all 250ms ease-out;
    color: #127F3F;
}

.btn:disabled, .btn.disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

.btn:disabled:hover, .btn.disabled:hover {
    background-color: #127F3F;
}

.btn.small {
    font-size: 0.7619rem;
    padding: 0.5rem 1.47619rem;
}

.btn.large {
    font-size: 1.25rem;
    padding: 1rem 2.95238rem;
}

.btn-dark {
    background-color: #127F3F;
    border-color: #127F3F;
    color: #ffffff;
}

.btn-dark:hover {
    background-color: transparent;
    border-color: #127F3F;
    color: #127F3F;
}

.btn-dark.outline {
    background-color: transparent;
    color: #127F3F;
}

.btn-dark:hover.outline {
    color: #ffffff;
    background-color: #127F3F;
}

.btn-light {
    background-color: #ffffff;
    border-color: #ffffff;
    color: #ffffff;
}

.btn-light:hover {
    background-color: #ffffff;
    border-color: #ffffff;
    color: #127F3F;
}

.btn-light.outline {
    background-color: transparent;
    color: #ffffff;
}

.btn-light:hover.outline {
    background-color: #ffffff;
    color: #127F3F;
}

.btn--mini {
    padding: 0.5rem 1rem;
    font-size: 0.5rem;
    letter-spacing: 1px;
}

.btn--mini--gr {
    padding: 0.5rem 1rem;
    font-size: 0.5rem;
    letter-spacing: 1px;
    background-color: #95989A;
    border-color: #95989A;
}

.btn--mini--gr:hover {
    color: #95989A;
    border-color: #95989A;
}

/*
Base Form Styles
===============================================*/
select, input[type="checkbox"] + label::before, input[type="radio"] + label::before, input[type=text],
input[type=password],
input[type=search],
input[type=email],
input[type=url],
input[type=tel],
input[type=date],
input[type=number],
input[type=time],
textarea {
    display: inline-block;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: .2rem;
    padding: .5rem;
}

/*
Forms
----------------------------------*/
/*
Base select styles
-----------------------------------------*/
select {
    position: relative;
    display: inline-block;
    width: 100%;
    cursor: pointer;
    outline: 0;
    background-clip: padding-box;
    background-image: url('data:image/svg+xml;utf8,<svg width="7" height="14" viewBox="0 0 7 14" xmlns="http://www.w3.org/2000/svg"><title>icon-form-dropdown</title><path d="M2.922 13.664c.32.447.836.45 1.157 0l2.763-3.86c.32-.447.135-.81-.414-.81H.57c-.55 0-.736.36-.415.81l2.765 3.86zm0-13.328c.32-.447.836-.45 1.157 0l2.763 3.86c.32.447.135.81-.414.81H.57c-.55 0-.736-.36-.415-.81L2.922.337z" fill="#7B8994" fill-rule="evenodd"/></svg>');
    background-size: 7px 14px;
    background-position: right 10px center;
    background-repeat: no-repeat;
    padding-right: 27px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

/* Undo the Firefox inner focus ring */
select:focus:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
}

/* Focus */
select:focus {
    box-shadow: 0 0 0 .075rem #fff, 0 0 0 .2rem #0074d9;
}

/* Active/open */
.select:active {
    color: #fff;
    background-color: #0074d9;
}

/* Hide the arrow in IE10 and up */
select::-ms-expand {
    display: none;
}

/* Media query to target Firefox only */
@-moz-document url-prefix() {
  /* Firefox hack to hide the arrow */
  /* <option> elements inherit styles from <select>, so reset them. */
    select {
        text-indent: 0.01px;
        text-overflow: '';
        padding-right: 1rem;
    }

    option {
        background-color: #fff;
    }
}

/* IE9 hack to hide the arrow */
@media screen and (min-width: 0\0) {
    select {
        z-index: 1;
        padding: .5rem 1.5rem .5rem 1rem;
    }

    select:after {
        z-index: 5;
    }

    select:before {
        position: absolute;
        top: 0;
        right: 1rem;
        bottom: 0;
        z-index: 2;
        content: "";
        display: block;
        width: 1.5rem;
        background-color: #eee;
    }

    select:hover,
  select:focus,
  select:active {
        color: #555;
        background-color: #eee;
    }
}

/*
Base checkbox styles
-----------------------------------------*/
input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    clip: rect(0 0 0 0);
}

input[type="checkbox"] + label::before {
    content: '';
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
}

input[type="checkbox"]:checked + label::before {
    content: '';
    background-color: #666;
}

/*
Base radio styles
-----------------------------------------*/
input[type="radio"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    clip: rect(0 0 0 0);
}

input[type="radio"] + label::before {
    content: '';
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
    border-radius: 50%;
}

input[type="radio"]:checked + label::before {
    content: '';
    background-color: #666;
}

/*
Base text input styles
-----------------------------------------*/
/*
Variables
------------------------------------*/
/*
Field Groups
------------------------------------*/
fieldset {
    border: 1px solid #000000;
    margin: 0 0 1rem;
    padding: 2.95238rem;
}

input,
label,
select {
    display: block;
    font-size: 1rem;
}

label {
    font-weight: 600;
    margin-bottom: 0.5rem;
}

label.required::after {
    content: "*";
}

label abbr {
    display: none;
}

/*
Text Inputs
------------------------------------*/
textarea {
    resize: vertical;
}

input[type="search"] {
    appearance: none;
}

/*---------------------------------------------
Import Components
---------------------------------------------*/
/*
Import all components here
-------------------------------------*/
/* 
Article Block Component
---------------------------
@description: 
------------------------------------------------*/
.ArticleBlock {
    max-width: 1150px;
    margin-right: auto;
    margin-left: auto;
    padding-left: 1.47619rem;
    padding-right: 1.47619rem;
    max-width: 800px;
    padding-top: 2.95238rem;
    padding-bottom: 2.95238rem;
}

.ArticleBlock {
    *zoom: 1;
}

.ArticleBlock:before, .ArticleBlock:after {
    content: "";
    display: table;
}

.ArticleBlock:after {
    clear: both;
}

@media (min-width: 48em) {
    .ArticleBlock {
        padding-top: 5.90476rem;
        padding-bottom: 5.90476rem;
    }
}

/*
Article Typography
------------------------------------------------*/
.ArticleBlock h2 {
    font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
    font-weight: 300;
    margin-bottom: 1.96825rem;
}

.ArticleBlock h2.lede {
    font-size: 1.323809524rem;
    font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
    font-weight: 200;
}

@media (min-width: 62em) {
    .ArticleBlock h2.lede {
        margin-bottom: 2.95238rem;
    }
}

.Article h2 strong {
    font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
    font-weight: 900;
}

.ArticleBlock p {
    font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
    font-weight: 300;
}

.ArticleBlock ul,
.ArticleBlock ol {
    font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
    font-weight: 300;
    margin-bottom: 1.96825rem;
    padding-left: 1rem;
}

.ArticleBlock a {
    color: #95989A;
    box-shadow: inset 0 -1px 0 0 #127F3F;
    transition: box-shadow 250ms ease-out;
}

.ArticleBlock a:hover {
    box-shadow: inset 0 -6px 0 0 #127F3F;
}

/* 
Feature Block Component
---------------------------
@description: 
------------------------------------------------*/
.FeatureBlock {
    position: relative;
    width: 100%;
    height: 85%;
    min-height: 554px;
    background-color: #f5f5f5;
}

@media (min-width: 34em) {
    .FeatureBlock {
        min-height: 25em;
    }
}

@media (min-width: 48em) {
    .FeatureBlock {
        min-height: 640px;
    }
}

@media (min-width: 62em) {
    .FeatureBlock {
        min-height: 768px;
    }
}

.featureBlock.imgBkg {
    background-image: url(http://www.fillmurray.com/g/1200/900);
    background-position: center;
    background-size: cover;
}

.FeatureBlock-videoBkg {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin: 0;
}

.FeatureBlock-video {
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.featureBlock-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
}

.featureBlock-wrapper.color-overlay {
    background-color: rgba(22, 22, 255, 0.7);
}

.FeatureBlock-content {
    position: absolute;
    width: 100%;
    opacity: 0;
    transition: opacity 500ms ease-out;
  /* Postioning of Content:
  x, y:  
  c = centered
  t = top
  b = bottom
  l = left
  r = right
  */
}

@media (min-width: 34em) {
    .FeatureBlock-content {
        width: 100%;
    }
}

.FeatureBlock-content.c-c {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.FeatureBlock-content.c-t {
    margin-top: 11.80952rem;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
}

.FeatureBlock-content.c-l {
    margin-left: 11.80952rem;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
}

.FeatureBlock-content.c-r {
    right: 0%;
    margin-right: 11.80952rem;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
}

.FeatureBlock-content.l-t {
    margin-top: 11.80952rem;
    margin-left: 11.80952rem;
}

.FeatureBlock-content.l-b {
    bottom: 0%;
    margin-bottom: 11.80952rem;
    margin-left: 11.80952rem;
}

.FeatureBlock-content.r-t {
    right: 0%;
    margin-top: 11.80952rem;
    margin-right: 11.80952rem;
}

.FeatureBlock-content.r-b {
    bottom: 0%;
    right: 0%;
    margin-bottom: 11.80952rem;
    margin-right: 11.80952rem;
}

/*
Feature Block Down Arrow
----------------------------------------*/
.FeatureBlock__arrow {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    width: 20px;
    height: 20px;
    bottom: 2.95238rem;
    opacity: 0;
    animation: arrow-pulse 2s infinite;
    transition: opacity 500ms ease-out;
}

@keyframes arrow-pulse {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(10px);
    }

    100% {
        transform: translateY(0);
    }
}

.top--0 .FeatureBlock__arrow {
    opacity: 1;
}

/*
Feature Block Title
----------------------------------------*/
.top--0 .FeatureBlock-content {
    opacity: 1;
}

.FeatureBlock__title {
    color: #ffffff;
}

.home .FeatureBlock__title {
    font-size: 1.875rem;
}

@media (min-width: 34em) {
    .home .FeatureBlock__title {
        font-size: 3rem;
        letter-spacing: -0.75px;
    }
}

@media (min-width: 48em) {
    .home .FeatureBlock__title {
        font-size: 3.619047619rem;
    }
}

/*
Page Header
----------------------------------------*/
.FeatureBlock.FeatureBlock--page-header {
    background-color: #127F3F;
    height: auto;
    min-height: unset;
}

.FeatureBlock.FeatureBlock--page-header .FeatureBlock__content {
    padding: 0 2.95238rem;
    text-align: center;
}

@media (min-width: 48em) {
    .FeatureBlock.FeatureBlock--page-header .FeatureBlock__content {
        padding: 0 5.90476rem;
    }
}

.FeatureBlock.FeatureBlock--page-header .FeatureBlock__title {
    padding: 7.38095rem 0 3.93651rem;
    max-width: 1150px;
    margin: 0 auto;
    opacity: 0;
    transition: opacity 500ms ease-out;
}

@media (min-width: 48em) {
    .FeatureBlock.FeatureBlock--page-header .FeatureBlock__title {
        padding: 11.80952rem 0 5.90476rem;
    }
}

.top--0 .FeatureBlock.FeatureBlock--page-header .FeatureBlock__title {
    opacity: 1;
}

/* 
Footer Component
---------------------------
@description: 
------------------------------------------------*/
.Footer {
    text-align: center;
    color: #ffffff;
    background-color: #000000;
}

.Footer__content {
    max-width: 1150px;
    margin-right: auto;
    margin-left: auto;
    padding-left: 1.47619rem;
    padding-right: 1.47619rem;
    padding-top: 2.95238rem;
    padding-bottom: 2.95238rem;
}

.Footer__content {
    *zoom: 1;
}

.Footer__content:before, .Footer__content:after {
    content: "";
    display: table;
}

.Footer__content:after {
    clear: both;
}

@media (min-width: 48em) {
    .Footer__content {
        padding-top: 5.90476rem;
    }
}

.Footer__logo {
    width: 8.333333333rem;
}

.Footer__name {
    font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
    font-weight: 400;
    margin-top: 1rem;
    text-transform: uppercase;
    letter-spacing: 0.75px;
}

.Footer__tagline {
    margin-bottom: 2.95238rem;
    font-size: 1rem;
    text-transform: uppercase;
    letter-spacing: 1.25px;
}

.Footer__contact {
    font-size: 0.761904762rem;
    font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
    font-weight: 300;
    color: #ffffff;
    letter-spacing: 0.75px;
}

.Footer__contact strong {
    font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
    font-weight: 700;
    letter-spacing: 1.25px;
}

.Footer__contact__link {
    color: #ffffff;
}

.Footer__copyright {
    font-size: 0.761904762rem;
    color: #ffffff;
}

/* 
NavBar Component
---------------------------
@description: 
------------------------------------------------*/
.NavBar {
    width: 100%;
    position: fixed;
    z-index: 4000;
    background-color: #ffffff;
    transition: background-color 250ms ease-out;
}

@media (min-width: 62em) {
    .NavBar {
        background-color: transparent;
    }
}

.NavBar:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 7px 8px 15px -7px rgba(0, 0, 0, 0.09);
    opacity: 0;
    z-index: -1;
    transition: opacity 250ms ease-out;
}

/*
Feature Area Hidden
---------------------------------------*/
.featureArea--hidden .Navbar {
    background-color: #ffffff;
}

.featureArea--hidden .NavBar:after {
    opacity: 1;
    transition: opacity 250ms;
}

.NavBar.hide--nav {
    transform: translateY(-100%);
}

.NavBar-wrapper {
    width: 100%;
    position: relative;
}

/*
** NavBar Logo
----------------------------------------------*/
.NavBar-anchor {
    display: block;
    float: left;
    overflow: hidden;
    margin-top: 0.98413rem;
    margin-left: 1.47619rem;
    margin-bottom: 0.98413rem;
    cursor: pointer;
    z-index: 5000;
}

@media (min-width: 62em) {
    .NavBar-anchor {
        position: absolute;
        top: 0;
        left: 0;
        width: 170px;
        height: 170px;
        margin-top: 1.47619rem;
        opacity: 1;
        transition: opacity 250ms ease-out, height 175ms ease-out;
    }
}

.NavBar-anchor .NavBar-logo--full {
    display: none;
}

@media (min-width: 62em) {
    .NavBar-anchor .NavBar-logo--full {
        display: block;
        width: 170px;
        transition: transform 250ms ease-out;
    }
}

.NavBar-anchor .NavBar-logo {
    width: 130px;
    transition: transform 250ms ease-out;
}

@media (min-width: 62em) {
    .NavBar-anchor .NavBar-logo {
        opacity: 0;
    }
}

/*
Feature Area Hidden
---------------------------------------------*/
@media (min-width: 62em) {
    .featureArea--hidden .NavBar-anchor {
        margin-top: 0;
        height: 73px;
    }
}

@media (min-width: 62em) {
    .featureArea--hidden .NavBar-logo--full {
        opacity: 0;
        transform: translateY(-202px);
    }
}

@media (min-width: 62em) {
    .featureArea--hidden .NavBar-logo {
        transform: translateY(-154px);
        opacity: 1;
    }
}

/*
** Global Site Navigation
----------------------------------------------*/
.NavBar .NavBar-menuWrapper {
    float: none;
    clear: both;
    transition: padding-top 250ms ease-out, max-height 250ms ease-out;
}

@media (min-width: 62em) {
    .NavBar .NavBar-menuWrapper {
        position: relative;
        padding-top: 82px;
        transition: padding-top 250ms ease-out;
    }
}

/*
Feature Area Hidden
---------------------------------------*/
.featureArea--hidden .NavBar .NavBar-menuWrapper {
    padding-top: 0;
}

.NavBar .NavBar-menu {
    position: relative;
    margin: 0 auto;
    overflow: visible;
    padding: 0 0 2.95238rem;
    text-align: center;
}

@media (min-width: 62em) {
    .NavBar .NavBar-menu {
        display: block;
        margin: 0;
        padding: 0;
    }
}

/*
** Global Site Navigation Link
----------------------------------------------*/
.NavBar .NavBar-item {
    display: block;
    font-size: 2rem;
    font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}

@media (min-width: 62em) {
    .NavBar .NavBar-item {
        position: relative;
        display: inline-block;
        font-size: 1rem;
        text-decoration: none;
        box-shadow: inset 0px 3px 0px 0px rgba(18, 127, 63, 0);
        transition: box-shadow 250ms ease-out;
    }
}

.NavBar .NavBar-item:last-child {
    margin-right: 0;
}

@media (min-width: 62em) {
    .NavBar-item.active {
        box-shadow: inset 0px 3px 0px 0px #127f3f;
    }
}

.NavBar .NavBar-link {
    font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
    font-weight: 700;
    display: block;
    padding: 0.7381rem 4.42857rem;
    opacity: 0;
    transition: opacity 250ms ease-out 300ms, color 250ms ease-out;
}

@media (min-width: 62em) {
    .NavBar .NavBar-link {
        display: inline-block;
        padding: 1rem;
        color: #ffffff;
        opacity: 1;
    }
}

/*
Feature Block Hidden
------------------------------------*/
.featureArea--hidden .NavBar .NavBar-link {
    color: #127F3F;
}

/*
** Mobile Navigation State
----------------------------------------------------------------*/
.NavBar .NavBar-mobileToggle {
    display: block;
    position: relative;
    float: right;
    overflow: hidden;
    padding: 0;
    width: 26px;
    height: 20px;
    margin-top: 27px;
    margin-right: 1.47619rem;
    font-size: 0;
    text-indent: -9999px;
    appearance: none;
    box-shadow: none;
    border-radius: none;
    border: none;
    cursor: pointer;
}

.NavBar .NavBar-mobileToggle:focus {
    outline: none;
}

@media (min-width: 62em) {
    .NavBar .NavBar-mobileToggle {
        display: none;
    }
}

.NavBar .NavBar-mobileToggle span {
    display: block;
    position: absolute;
    top: 9px;
    left: 0px;
    right: 0px;
    height: 2px;
    background: #127F3F;
    transition: background 250ms 0.3s;
}

.NavBar .NavBar-mobileToggle span::before,
.NavBar .NavBar-mobileToggle span::after {
    content: "";
    position: absolute;
    display: block;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #127F3F;
    transition-duration: 250ms, 250ms;
    transition-delay: 250ms, 0s;
}

.NavBar .NavBar-mobileToggle span::before {
    top: -9px;
    transition-property: top, transform;
}

.NavBar .NavBar-mobileToggle span::after {
    bottom: -9px;
    transition-property: bottom, transform;
}

/*
Mask
--------------------------------------------*/
.mask {
    transition: opacity 300ms;
    background: rgba(0, 0, 0, 0.5);
    visibility: hidden;
    position: fixed;
    opacity: 0;
    z-index: 2;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
}

@media (min-width: 62em) {
    .mask {
        display: none !important;
        opacity: 0 !important;
    }
}

.js-nav-active .mask {
    visibility: visible;
    opacity: 1;
}

.js .nav-collapse {
    clip: rect(0 0 0 0);
    max-height: 0;
    display: block;
    overflow: hidden;
    zoom: 1;
}

@media (min-width: 62em) {
    .js .nav-collapse {
        position: relative;
    }
}

@media (min-width: 62em) {
    .nav-collapse.closed {
        max-height: none;
    }
}

.nav-collapse.opened {
    max-height: 9999px;
}

.nav-toggle {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
}

@media (min-width: 62em) {
    .nav-toggle {
        display: none;
    }
}

/*
** Active state, i.e. menu open
---------------------------------------------*/
.js-nav-active .NavBar-link {
    opacity: 1;
    transition: opacity 250ms ease-out;
}

.js-nav-active .NavBar-mobileToggle span {
    background: none;
}

.js-nav-active .NavBar-mobileToggle span::before {
    top: -2px;
    transform: rotate(45deg);
}

.js-nav-active .NavBar-mobileToggle span::after {
    bottom: 2px;
    transform: rotate(-45deg);
}

.js-nav-active .NavBar-mobileToggle span::before,
.js-nav-active .NavBar-mobileToggle span::after {
    background-color: #127F3F;
    transition-delay: 0s, 0.3s;
}

/* 
Pagination Component
---------------------------
@description: 
------------------------------------------------*/
.Pagination {
    max-width: 1150px;
    margin-right: auto;
    margin-left: auto;
    padding-left: 1.47619rem;
    padding-right: 1.47619rem;
    max-width: 800px;
    text-align: center;
    padding-top: 0;
    padding-bottom: 2.95238rem;
}

.Pagination {
    *zoom: 1;
}

.Pagination:before, .Pagination:after {
    content: "";
    display: table;
}

.Pagination:after {
    clear: both;
}

.Pagination__prev,
.Pagination__next {
    display: inline-block;
}

.Pagination__prev {
    float: left;
}

.Pagination__next {
    float: right;
}

.Pagination__prev__img {
    display: inline-block;
    height: 1rem;
    vertical-align: middle;
    margin-bottom: 3px;
    margin-right: 6px;
}

.Pagination__next__img {
    display: inline-block;
    height: 1rem;
    vertical-align: middle;
    margin-bottom: 3px;
    margin-left: 6px;
}

.Pagination__pageList {
    display: inline-block;
    margin: 0 2.95238rem;
    padding-left: 0;
    list-style: none;
}

.pageList__page {
    display: inline-block;
    font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}

/* 
Partner List Component
---------------------------
@description: 
------------------------------------------------*/
.PartnerList {
    margin-bottom: 2.95238rem;
    padding-left: 0;
    font-size: 0.857142857rem;
    line-height: 1.45;
    list-style: none;
    columns: 1;
}

@media (min-width: 48em) {
    .PartnerList {
        columns: 2;
        column-gap: 2.95238rem;
        text-align: left;
    }
}

@media (min-width: 62em) {
    .PartnerList {
        columns: 3;
    }
}

.PartnerList.PartnerList--small {
    font-size: 0.761904762rem;
}

.PartnerList__item {
    margin-bottom: 0.5rem;
}

/* 
Priorities Component
---------------------------
@description: 
------------------------------------------------*/
@media (min-width: 48em) {
    .Priorities {
        margin-left: -0.7381rem;
        margin-right: -0.7381rem;
    }

    .Priorities {
        *zoom: 1;
    }

    .Priorities:before, .Priorities:after {
        content: "";
        display: table;
    }

    .Priorities:after {
        clear: both;
    }
}

.Priorities__item {
    margin: 0;
}

@media (min-width: 48em) {
    .Priorities__item {
        position: relative;
        float: left;
        min-height: 1px;
        padding-left: 0.7381rem;
        padding-right: 0.7381rem;
        width: 33.33333%;
    }
}

.Priorities__item__icon {
    width: 7.142857143rem;
    height: 7.142857143rem;
}

.Priorities__item__caption {
    margin-top: 1.47619rem;
}

.Priorities__item__caption .caption__headline {
    font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
    font-size: 1rem;
    font-weight: 900;
    line-height: 1.2;
    margin-bottom: 1rem;
    color: #127F3F;
}

.Priorities__item__caption .caption__description {
    font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
    font-weight: 300;
    font-size: 0.761904762rem;
}

/* 
Section Block Component
---------------------------
@description: 
------------------------------------------------*/
.SectionBlock {
    padding: 2.95238rem 0;
}

@media (min-width: 48em) {
    .SectionBlock {
        padding: 5.90476rem 0;
    }
}

.SectionBlock__content {
    max-width: 1150px;
    margin-right: auto;
    margin-left: auto;
    padding-left: 1.47619rem;
    padding-right: 1.47619rem;
}

.SectionBlock__content {
    *zoom: 1;
}

.SectionBlock__content:before, .SectionBlock__content:after {
    content: "";
    display: table;
}

.SectionBlock__content:after {
    clear: both;
}

.SectionBlock__icon {
    width: 1.904761905rem;
    margin-bottom: 1rem;
}

.SectionBlock__title {
    margin-bottom: 1.96825rem;
    color: #95989A;
    letter-spacing: 1px;
}

.SectionBlock--alternate:nth-child(even) {
    background-color: #F2F2F2;
}

.SectionBlock--alternate:nth-child(even) .SectionBlock__title {
    color: #000000;
}

.SectionBlock--imgBkg {
    background-position: center;
    background-size: cover;
}

.SectionBlock.SectionBlock--imgBkg .SectionBlock__title {
    color: #ffffff;
}

/*
Partners Section
-------------------------------------------*/
.SectionBlock.SectionBlock--partners {
    padding-top: 0;
}

/* 
Twitter Component
---------------------------
@description: 
------------------------------------------------*/
.Twitter {
    margin-bottom: 1.47619rem;
}

.Twitter {
    *zoom: 1;
}

.Twitter:before, .Twitter:after {
    content: "";
    display: table;
}

.Twitter:after {
    clear: both;
}

@media (min-width: 48em) {
    .flexbox .Twitter {
        display: flex;
        flex-wrap: wrap;
    }
}

/* Clear pseudo-elements to fix safri bug(da fuc safari????):
https://stackoverflow.com/questions/34250282/flexbox-safari-bug-flex-wrap
-------------------------------------------*/
.flexbox .Twitter:before,
.flexbox .Twitter:after {
    content: normal;
}

.Twitter__item {
    text-align: left;
    margin-bottom: 1.47619rem;
}

@media (min-width: 48em) {
    .Twitter__item {
        position: relative;
        float: left;
        min-height: 1px;
        padding-left: 0.7381rem;
        padding-right: 0.7381rem;
        width: 33.33333%;
        margin-bottom: 0;
    }
}

@media (min-width: 48em) {
    .flexbox .Twitter__item {
        display: flex;
    }
}

.Twitter__item__content {
    padding: 1.47619rem;
    background-color: #ffffff;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.06);
}

@media (min-width: 48em) {
    .flexbox .Twitter__item__content {
        flex: 1;
    }
}

.Twitter__item__date {
    font-size: 0.761904762rem;
    font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
    font-weight: 700;
    margin-bottom: 1rem;
}

.Twitter__item__body {
    font-size: 0.761904762rem;
}

/* 
Updates Component
---------------------------
@description: 
------------------------------------------------*/
.Updates {
    max-width: 1150px;
    margin-right: auto;
    margin-left: auto;
    padding-left: 1.47619rem;
    padding-right: 1.47619rem;
    margin-bottom: 1rem;
}

.Updates {
    *zoom: 1;
}

.Updates:before, .Updates:after {
    content: "";
    display: table;
}

.Updates:after {
    clear: both;
}

@media (min-width: 48em) {
    .Updates {
        margin-bottom: 1.47619rem;
    }
}

.Updates__item {
    text-align: left;
    margin-bottom: 1rem;
}

@media (min-width: 48em) {
    .Updates__item {
        position: relative;
        float: left;
        min-height: 1px;
        padding-left: 0.7381rem;
        padding-right: 0.7381rem;
        width: 33.33333%;
        margin-bottom: 0;
    }
}

.Updates__item__date {
    font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
    font-weight: 700;
    font-size: 0.761904762rem;
    margin-bottom: 1rem;
}

.Updates__item__link {
    display: block;
    color: #95989A;
}

.Updates__item__link:hover {
    color: #127F3F;
}

.Updates__item__title {
    font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
    font-weight: 300;
}

/* 
Updates List Component
---------------------------
@description: 
------------------------------------------------*/
.UpdatesList {
    max-width: 1150px;
    margin-right: auto;
    margin-left: auto;
    padding-left: 1.47619rem;
    padding-right: 1.47619rem;
    max-width: 800px;
    padding-top: 2.95238rem;
    padding-bottom: 2.95238rem;
}

.UpdatesList {
    *zoom: 1;
}

.UpdatesList:before, .UpdatesList:after {
    content: "";
    display: table;
}

.UpdatesList:after {
    clear: both;
}

@media (min-width: 48em) {
    .UpdatesList {
        padding-top: 2.95238rem;
        padding-bottom: 2.95238rem;
    }
}

.UpdatesList__count {
    font-size: 0.761904762rem;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.UpdatesList__item {
    border-bottom: 1px solid #F2F2F2;
}

.UpdatesList__item {
    padding: 1.96825rem 0;
}

.UpdateList__item__date {
    font-size: 0.761904762rem;
    font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
    font-weight: 700;
    margin-bottom: 0.5rem;
    letter-spacing: 0.5px;
}

.flexbox .UpdatesList__item__inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.UpdatesList__item__link {
    display: block;
    width: 70%;
    margin-right: 1.47619rem;
    color: #95989A;
}

.UpdatesList__item__link:hover {
    color: #127F3F;
}

.UpdatesList__item__title {
    font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
    font-weight: 300;
    margin-bottom: 0;
}

/*---------------------------------------------
Import Utilities
---------------------------------------------*/
/*
Utility Styles
--------------------
Usefull utility classes to be used for various purposes
------------------------------------------------------------------*/
/*
Animations
--------------
@description:
----------------------------------------------*/
/*
Fade
----------------------------------------------------------*/
/* 
Variables
------------------------*/
/*
Keyframes
----------------------------------------------------------*/
@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fade-out {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes fade-inDown {
    0% {
        opacity: 0;
        transform: translateY(-30%);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fade-outDown {
    0% {
        opacity: 1;
        transform: translateY(0);
    }

    100% {
        opacity: 0;
        transform: translateY(30%);
    }
}

@keyframes fade-inUp {
    0% {
        opacity: 0;
        transform: translateY(30%);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fade-outUp {
    0% {
        opacity: 1;
        transform: translateY(0);
    }

    100% {
        opacity: 0;
        transform: translateY(-30%);
    }
}

@keyframes fade-inLeft {
    0% {
        opacity: 0;
        transform: translateX(-30%);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fade-outLeft {
    0% {
        opacity: 1;
        transform: translateX(0);
    }

    100% {
        opacity: 0;
        transform: translateX(-30%);
    }
}

@keyframes fade-inRight {
    0% {
        opacity: 0;
        transform: translateX(30%);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fade-outRight {
    0% {
        opacity: 1;
        transform: translateX(0);
    }

    100% {
        opacity: 0;
        transform: translateX(30%);
    }
}

/*
Animation Classes
----------------------------------------------------------*/
.fade-in {
    animation-name: fade-in;
    animation-duration: 750ms;
    animation-timing-function: cubic-bezier(0.36, 0.45, 0.67, 1);
}

.fade-out {
    animation-name: fade-out;
    animation-duration: 750ms;
    animation-timing-function: cubic-bezier(0.36, 0.45, 0.67, 1);
}

.fade-inDown {
    animation-name: fade-inDown;
    animation-duration: 750ms;
    animation-timing-function: cubic-bezier(0.36, 0.45, 0.67, 1);
}

.fade-outDown {
    animation-name: fade-outDown;
    animation-duration: 750ms;
    animation-timing-function: cubic-bezier(0.36, 0.45, 0.67, 1);
}

.fade-inUp {
    animation-name: fade-inUp;
    animation-duration: 750ms;
    animation-timing-function: cubic-bezier(0.36, 0.45, 0.67, 1);
}

.fade-outUp {
    animation-name: fade-outUp;
    animation-duration: 750ms;
    animation-timing-function: cubic-bezier(0.36, 0.45, 0.67, 1);
}

.fade-inLeft {
    animation-name: fade-inLeft;
    animation-duration: 750ms;
    animation-timing-function: cubic-bezier(0.36, 0.45, 0.67, 1);
}

.fade-outLeft {
    animation-name: fade-outLeft;
    animation-duration: 750ms;
    animation-timing-function: cubic-bezier(0.36, 0.45, 0.67, 1);
}

.fade-inRight {
    animation-name: fade-inRight;
    animation-duration: 750ms;
    animation-timing-function: cubic-bezier(0.36, 0.45, 0.67, 1);
}

.fade-outRight {
    animation-name: fade-outRight;
    animation-duration: 750ms;
    animation-timing-function: cubic-bezier(0.36, 0.45, 0.67, 1);
}

/*
Scale
----------------------------------------------------------*/
/* 
Variables
------------------------*/
/*
Keyframes
----------------------------------------------------------*/
@keyframes scale-up {
    0% {
        opacity: 0;
        transform: scale(0.75) translateX(0px) translateY(0px);
    }

    100% {
        opacity: 1;
        transform: scale(1) translateX(0px) translateY(0px);
    }
}

@keyframes scale-down {
    0% {
        opacity: 1;
        transform: scale(1) translateX(0px) translateY(0px);
    }

    100% {
        opacity: 0;
        transform: scale(0.75) translateX(0px) translateY(0px);
    }
}

/*
Animation Classes
----------------------------------------------------------*/
.scale-up {
    animation-name: scale-up;
    animation-duration: 300ms;
    animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.scale-down {
    animation-name: scale-down;
    animation-duration: 300ms;
    animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

/*
Slide
----------------------------------------------------------*/
/* 
Variables
------------------------*/
/*
Keyframes
----------------------------------------------------------*/
@keyframes slide-inUp {
    0% {
        transform: translate3d(0, 100%, 0);
    }

    100% {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes slide-inDown {
    0% {
        transform: translate3d(0, -100%, 0);
    }

    100% {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes slide-inLeft {
    0% {
        transform: translate3d(-100%, 0, 0);
    }

    100% {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes slide-inRight {
    0% {
        transform: translate3d(100%, 0, 0);
    }

    100% {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes slide-outUp {
    0% {
        transform: translate3d(0, 0, 0);
    }

    100% {
        transform: translate3d(0, -100%, 0);
    }
}

@keyframes slide-outDown {
    0% {
        transform: translate3d(0, 0, 0);
    }

    100% {
        transform: translate3d(0, 100%, 0);
    }
}

@keyframes slide-outLeft {
    0% {
        transform: translate3d(0, 0, 0);
    }

    100% {
        transform: translate3d(-100%, 0, 0);
    }
}

@keyframes slide-outRight {
    0% {
        transform: translate3d(0, 0, 0);
    }

    100% {
        transform: translate3d(100%, 0, 0);
    }
}

/*
Animation Classes
----------------------------------------------------------*/
.slide-inUp {
    animation-name: slide-inUp;
    animation-duration: 300ms;
    animation-timing-function: cubic-bezier(0.36, 0.45, 0.67, 1);
}

.slide-inDown {
    animation-name: slide-inDown;
    animation-duration: 300ms;
    animation-timing-function: cubic-bezier(0.36, 0.45, 0.67, 1);
}

.slide-inLeft {
    animation-name: slide-inLeft;
    animation-duration: 300ms;
    animation-timing-function: cubic-bezier(0.36, 0.45, 0.67, 1);
}

.slide-inRight {
    animation-name: slide-inRight;
    animation-duration: 300ms;
    animation-timing-function: cubic-bezier(0.36, 0.45, 0.67, 1);
}

.slide-outUp {
    animation-name: slide-outUp;
    animation-duration: 300ms;
    animation-timing-function: cubic-bezier(0.36, 0.45, 0.67, 1);
}

.slide-outDown {
    animation-name: slide-outDown;
    animation-duration: 300ms;
    animation-timing-function: cubic-bezier(0.36, 0.45, 0.67, 1);
}

.slide-outLeft {
    animation-name: slide-outLeft;
    animation-duration: 300ms;
    animation-timing-function: cubic-bezier(0.36, 0.45, 0.67, 1);
}

.slide-outRight {
    animation-name: slide-outRight;
    animation-duration: 300ms;
    animation-timing-function: cubic-bezier(0.36, 0.45, 0.67, 1);
}

/* 
Colors
---------------------------
@description: Special classes that are used for color
--------------------------------------------------------*/
.clearfix {
    *zoom: 1;
}

.clearfix:before, .clearfix:after {
    content: "";
    display: table;
}

.clearfix:after {
    clear: both;
}

/* 
Positioning
---------------------------
@description:
--------------------------------------------------------*/
/* 
Floats
----------------------------*/
.pull-right {
    float: right !important;
}

.pull-left {
    float: left !important;
}

/* 
Fixed Positioning
----------------------------*/
.pos-f-t {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 6000;
}

.pos-f-b {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 6000;
}

.pos-f-r {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 6000;
}

.pos-f-l {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 6000;
}

/* 
Sizing
---------------------------
@description:
--------------------------------------------------------*/
/* 
Spacing
---------------------------
@description: Special classes that are used for spacing
--------------------------------------------------------*/
/* 
Spacing Variables
----------------------------*/
/* 
Multiplier Variables
----------------------------*/
/* 
Margin
----------------------------*/
.m-a-0 {
    margin: 0 !important;
}

.m-t-0 {
    margin-top: 0 !important;
}

.m-r-0 {
    margin-right: 0 !important;
}

.m-b-0 {
    margin-bottom: 0 !important;
}

.m-l-0 {
    margin-left: 0 !important;
}

.m-x-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
}

.m-y-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.m-a {
    margin: 2.95238rem !important;
}

.m-t {
    margin-top: 2.95238rem !important;
}

.m-r {
    margin-right: 2.95238rem !important;
}

.m-b {
    margin-bottom: 2.95238rem !important;
}

.m-l {
    margin-left: 2.95238rem !important;
}

.m-x {
    margin-right: 2.95238rem !important;
    margin-left: 2.95238rem !important;
}

.m-y {
    margin-top: 2.95238rem !important;
    margin-bottom: 2.95238rem !important;
}

.m-x-auto {
    margin-right: auto !important;
    margin-left: auto !important;
}

.m-a-md {
    margin: 4.42857rem !important;
}

.m-t-md {
    margin-top: 4.42857rem !important;
}

.m-r-md {
    margin-right: 4.42857rem !important;
}

.m-b-md {
    margin-bottom: 4.42857rem !important;
}

.m-l-md {
    margin-left: 4.42857rem !important;
}

.m-x-md {
    margin-right: 4.42857rem !important;
    margin-left: 4.42857rem !important;
}

.m-y-md {
    margin-top: 4.42857rem !important;
    margin-bottom: 4.42857rem !important;
}

.m-a-lg {
    margin: 8.85714rem !important;
}

.m-t-lg {
    margin-top: 8.85714rem !important;
}

.m-r-lg {
    margin-right: 8.85714rem !important;
}

.m-b-lg {
    margin-bottom: 8.85714rem !important;
}

.m-l-lg {
    margin-left: 8.85714rem !important;
}

.m-x-lg {
    margin-right: 8.85714rem !important;
    margin-left: 8.85714rem !important;
}

.m-y-lg {
    margin-top: 8.85714rem !important;
    margin-bottom: 8.85714rem !important;
}

/* 
Padding
----------------------------*/
.p-a-0 {
    padding: 0 !important;
}

.p-t-0 {
    padding-top: 0 !important;
}

.p-r-0 {
    padding-right: 0 !important;
}

.p-b-0 {
    padding-bottom: 0 !important;
}

.p-l-0 {
    padding-left: 0 !important;
}

.p-x-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.p-y-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.p-a {
    padding: 2.95238rem !important;
}

.p-t {
    padding-top: 2.95238rem !important;
}

.p-r {
    padding-right: 2.95238rem !important;
}

.p-b {
    padding-bottom: 2.95238rem !important;
}

.p-l {
    padding-left: 2.95238rem !important;
}

.p-x {
    padding-right: 2.95238rem !important;
    padding-left: 2.95238rem !important;
}

.p-y {
    padding-top: 2.95238rem !important;
    padding-bottom: 2.95238rem !important;
}

.p-a-md {
    padding: 4.42857rem !important;
}

.p-t-md {
    padding-top: 4.42857rem !important;
}

.p-r-md {
    padding-right: 4.42857rem !important;
}

.p-b-md {
    padding-bottom: 4.42857rem !important;
}

.p-l-md {
    padding-left: 4.42857rem !important;
}

.p-x-md {
    padding-right: 4.42857rem !important;
    padding-left: 4.42857rem !important;
}

.p-y-md {
    padding-top: 4.42857rem !important;
    padding-bottom: 4.42857rem !important;
}

.p-a-lg {
    padding: 8.85714rem !important;
}

.p-t-lg {
    padding-top: 8.85714rem !important;
}

.p-r-lg {
    padding-right: 8.85714rem !important;
}

.p-b-lg {
    padding-bottom: 8.85714rem !important;
}

.p-l-lg {
    padding-left: 8.85714rem !important;
}

.p-x-lg {
    padding-right: 8.85714rem !important;
    padding-left: 8.85714rem !important;
}

.p-y-lg {
    padding-top: 8.85714rem !important;
    padding-bottom: 8.85714rem !important;
}

/* 
Typography
---------------------------
@description: 
--------------------------------------------------------*/
/* 
Alignment
----------------------------*/
.text-center {
    text-align: center !important;
}

.text-justify {
    text-align: justify !important;
}

.text-nowrap {
    white-space: nowrap !important;
}

.text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* 
Responsive alignment
----------------------------*/
.text-xs-left {
    text-align: left !important;
}

.text-xs-right {
    text-align: right !important;
}

.text-xs-center {
    text-align: center !important;
}

@media (min-width: 34em) {
    .text-sm-left {
        text-align: left !important;
    }

    .text-sm-right {
        text-align: right !important;
    }

    .text-sm-center {
        text-align: center !important;
    }
}

@media (min-width: 48em) {
    .text-md-left {
        text-align: left !important;
    }

    .text-md-right {
        text-align: right !important;
    }

    .text-md-center {
        text-align: center !important;
    }
}

@media (min-width: 62em) {
    .text-lg-left {
        text-align: left !important;
    }

    .text-lg-right {
        text-align: right !important;
    }

    .text-lg-center {
        text-align: center !important;
    }
}

@media (min-width: 75em) {
    .text-xl-left {
        text-align: left !important;
    }

    .text-xl-right {
        text-align: right !important;
    }

    .text-xl-center {
        text-align: center !important;
    }
}

/* 
Transformation
----------------------------*/
.text-lowercase {
    text-transform: lowercase !important;
}

.text-uppercase {
    text-transform: uppercase !important;
}

.text-capitalize {
    text-transform: capitalize !important;
}

/* 
Weight and italics
----------------------------*/
.font-weight-normal {
    font-weight: normal;
}

.font-weight-bold {
    font-weight: bold;
}

.font-italic {
    font-style: italic;
}

/* 
Underline
----------------------------*/
.underline {
    text-decoration: underline !important;
}

/* 
Strike through
----------------------------*/
.strike {
    text-decoration: line-through !important;
}

/* 
Break Word & No Wrap
----------------------------*/
.force-break {
    word-wrap: break-word !important;
}

.nowrap {
    white-space: nowrap !important;
}

.wrap {
    white-space: normal !important;
}

.word-break {
    word-break: break-all !important;
}

/* 
Contextual colors
----------------------------*/
/*
@include text-emphasis-variant('.text-primary', $brand-primary);
@include text-emphasis-variant('.text-success', $brand-success);
@include text-emphasis-variant('.text-info', $brand-info);
@include text-emphasis-variant('.text-warning', $brand-warning);
@include text-emphasis-variant('.text-danger', $brand-danger);
*/
/* 
Visibility utilities
---------------------------
@description: 
--------------------------------------------------------*/
.invisible {
    visibility: hidden !important;
}

.hidden-xs-up {
    display: none !important;
}

@media (max-width: 33.9em) {
    .hidden-xs-down {
        display: none !important;
    }
}

@media (min-width: 34em) {
    .hidden-sm-up {
        display: none !important;
    }
}

@media (max-width: 47.9em) {
    .hidden-sm-down {
        display: none !important;
    }
}

@media (min-width: 48em) {
    .hidden-md-up {
        display: none !important;
    }
}

@media (max-width: 61.9em) {
    .hidden-md-down {
        display: none !important;
    }
}

@media (min-width: 62em) {
    .hidden-lg-up {
        display: none !important;
    }
}

@media (max-width: 74.9em) {
    .hidden-lg-down {
        display: none !important;
    }
}

@media (min-width: 75em) {
    .hidden-xl-up {
        display: none !important;
    }
}

.hidden-xl-down {
    display: none !important;
}

/* 
Print utilities
---------------------------
Media queries are placed on the inside to be mixin-friendly.
--------------------------------------------------------*/
.visible-print-block {
    display: none !important;
}

@media print {
    .visible-print-block {
        display: block !important;
    }
}

.visible-print-inline {
    display: none !important;
}

@media print {
    .visible-print-inline {
        display: inline !important;
    }
}

.visible-print-inline-block {
    display: none !important;
}

@media print {
    .visible-print-inline-block {
        display: inline-block !important;
    }
}

@media print {
    .hidden-print {
        display: none !important;
    }
}

/* 
Responsive utilities
---------------------------
More easily include all the states for responsive-utilities.less. 
[converter] $parent hack
--------------------------------------------------------*/
