/* 
//=====
name: "Icon Font";
slug: base/icons
description: [
  Icon font
];  
=====//
 */

 // If you are using ripe's gulp-starter:
 // -------
 // DO NOT EDIT DIRECTLY! Generated by 'gulp/tasks/iconFont.js'
 // Check the 'config.yml' file for the location of the template scss file. 

@font-face {
  font-family: "farmbill-icons";
  src: url('../fonts/icons/farmbill-icons.eot');
  src: url('../fonts/icons/farmbill-icons.eot?#iefix') format('eot'),
    url('../fonts/icons/farmbill-icons.woff') format('woff'),
    url('../fonts/icons/farmbill-icons.ttf') format('truetype'),
    url('../fonts/icons/farmbill-icons.svg#farmbill-icons') format('svg');
}

@mixin icon-styles {
  font-family: "farmbill-icons";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  // speak: none; // only necessary if not using the private unicode range (firstGlyph option)
  text-decoration: none;
  text-transform: none;
}

%icon {
  @include icon-styles;
}

@function icon-char($filename) {
  $char: "";

  @if $filename == arrow-up {
    $char: "\E001";
  }
  @if $filename == check {
    $char: "\E002";
  }
  @if $filename == close-circle {
    $char: "\E003";
  }
  @if $filename == close {
    $char: "\E004";
  }
  @if $filename == dollar {
    $char: "\E005";
  }
  @if $filename == edit {
    $char: "\E006";
  }
  @if $filename == menu {
    $char: "\E007";
  }
  @if $filename == minus-circle {
    $char: "\E008";
  }
  @if $filename == navigate-down-circle {
    $char: "\E009";
  }
  @if $filename == navigate-down {
    $char: "\E00A";
  }
  @if $filename == navigate-up {
    $char: "\E00B";
  }
  @if $filename == plus {
    $char: "\E00C";
  }
  @if $filename == preview {
    $char: "\E00D";
  }
  @if $filename == renew {
    $char: "\E00E";
  }
  @if $filename == search {
    $char: "\E00F";
  }
  @if $filename == settings {
    $char: "\E010";
  }
  @if $filename == user {
    $char: "\E011";
  }

  @return $char;
}

@mixin icon($filename, $insert: before, $extend: true) {
  &:#{$insert} {
    @if $extend {
      @extend %icon;
    } @else {
      @include icon-styles;
    }
    content: icon-char($filename);
  }
}

.icon--arrow-up {
  @include icon(arrow-up);
}
.icon--check {
  @include icon(check);
}
.icon--close-circle {
  @include icon(close-circle);
}
.icon--close {
  @include icon(close);
}
.icon--dollar {
  @include icon(dollar);
}
.icon--edit {
  @include icon(edit);
}
.icon--menu {
  @include icon(menu);
}
.icon--minus-circle {
  @include icon(minus-circle);
}
.icon--navigate-down-circle {
  @include icon(navigate-down-circle);
}
.icon--navigate-down {
  @include icon(navigate-down);
}
.icon--navigate-up {
  @include icon(navigate-up);
}
.icon--plus {
  @include icon(plus);
}
.icon--preview {
  @include icon(preview);
}
.icon--renew {
  @include icon(renew);
}
.icon--search {
  @include icon(search);
}
.icon--settings {
  @include icon(settings);
}
.icon--user {
  @include icon(user);
}