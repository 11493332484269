/* 
Article Block Component
---------------------------
@description: 
------------------------------------------------*/

.ArticleBlock {
  @include make-container();
  max-width: 800px;
  padding-top: $base-spacing;
  padding-bottom: $base-spacing;

  @include media-breakpoint-up(md) {
    padding-top: $section-spacing;
    padding-bottom: $section-spacing;
  }
}

/*
Article Typography
------------------------------------------------*/
.ArticleBlock h2 {
  @include light-font;
  margin-bottom: $base-spacing / 1.5;
  
}

.ArticleBlock h2.lede {
  font-size: 1.323809524rem;
  @include ex-light-font;
  @include media-breakpoint-up(lg) {
    margin-bottom: $base-spacing;
  }
}

.Article h2 strong {
  @include black-font;
}

.ArticleBlock p {
  @include light-font;
}

.ArticleBlock ul,
.ArticleBlock ol {
  @include light-font; 
  margin-bottom: $base-spacing / 1.5;
  padding-left: 1rem;
}

.ArticleBlock a {
  color: color(grey,dark);
  box-shadow: inset 0 -1px 0 0 color(brand,green);
  transition: box-shadow $base-duration $base-timing;

  &:hover {
    box-shadow: inset 0 -6px 0 0 color(brand,green);
  }
}

