/* 
Pagination Component
---------------------------
@description: 
------------------------------------------------*/

.Pagination {
  @include make-container();
  max-width: 800px;
  text-align: center;
  padding-top: 0 ;
  padding-bottom: $section-spacing / 2;
}

.Pagination__prev,
.Pagination__next {
  display: inline-block;
}

.Pagination__prev {
  float: left;
}

.Pagination__next {
  float: right;
}

.Pagination__prev__img {
  display: inline-block;
  height: $base-font-size;
  vertical-align: middle;
  margin-bottom: 3px;
  margin-right: 6px;
}

.Pagination__next__img {
  display: inline-block;
  height: $base-font-size;
  vertical-align: middle;
  margin-bottom: 3px;
  margin-left: 6px;
}

.Pagination__pageList {
  display: inline-block;
  margin: 0 $base-spacing;
  padding-left: 0;
  list-style: none;
}

.pageList__page {
  display: inline-block;
  font-family: $bold-font-family;
}