/// Grid system
//
// Generate semantic grid columns with these mixins.
@mixin clearfix() {
  & {
    *zoom: 1;
  }

  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }

}

@mixin make-container($gutter: $grid-gutter-width * 2) {
  max-width: $max-width;
  margin-right: auto;
  margin-left: auto;
  padding-left:  ($gutter / 2);
  padding-right: ($gutter / 2);
  @include clearfix();
}

@mixin make-row($gutter: $grid-gutter-width) {
  @if $enable-flex {
    display: flex;
    flex-wrap: wrap;
  } @else {
    @include clearfix();
  }
  margin-left:  ($gutter / -2);
  margin-right: ($gutter / -2);
}

@mixin make-col($gutter: $grid-gutter-width) {
  position: relative;
  @if $enable-flex {
    // Do nothing
  } @else {
    float: left;
  }
  min-height: 1px;
  padding-left:  ($gutter / 2);
  padding-right: ($gutter / 2);
}

@mixin make-col-span($size, $columns: $grid-columns) {
  @if $enable-flex {
    flex: 0 0 percentage($size / $columns);
  } @else {
    width: percentage($size / $columns);
  }
}

@mixin make-col-offset($size, $columns: $grid-columns) {
  margin-left: percentage($size / $columns);
}

@mixin make-col-push($size, $columns: $grid-columns) {
  left: if($size > 0, percentage($size / $columns), auto);
}

@mixin make-col-pull($size, $columns: $grid-columns) {
  right: if($size > 0, percentage($size / $columns), auto);
}

@mixin make-col-modifier($type, $size, $columns) {
  // Work around the lack of dynamic mixin @include support (https://github.com/sass/sass/issues/626)
  @if $type == push {
    @include make-col-push($size, $columns);
  } @else if $type == pull {
    @include make-col-pull($size, $columns);
  } @else if $type == offset {
    @include make-col-offset($size, $columns);
  }
}

// Framework grid generation
//
// Used only by Bootstrap to generate the correct number of grid classes given
// any value of `$grid-columns`.
@mixin make-grid-columns($columns: $grid-columns, $gutter: $grid-gutter-width, $breakpoints: $grid-breakpoints) {
  // Common properties for all breakpoints
  %grid-column {
    position: relative;
    // Prevent columns from collapsing when empty
    min-height: 1px;
    // Inner gutter via padding
    padding-left: ($gutter / 2);
    padding-right: ($gutter / 2);
  }
  @each $breakpoint in map-keys($breakpoints) {
    @for $i from 1 through $columns {
      .col-#{$breakpoint}-#{$i} {
        @extend %grid-column;
      }
    }
    @include media-breakpoint-up($breakpoint) {
      // Work around cross-media @extend (https://github.com/sass/sass/issues/1050)
      %grid-column-float-#{$breakpoint} {
        @if $enable-flex {
          // Do nothing
        } @else {
          float: left;
        }
      }
      @for $i from 1 through $columns {
        .col-#{$breakpoint}-#{$i} {
          @extend %grid-column-float-#{$breakpoint} !optional;
          @include make-col-span($i, $columns);
        }
      }
      @each $modifier in (pull, push, offset) {
        @for $i from 0 through $columns {
          .col-#{$breakpoint}-#{$modifier}-#{$i} {
            @include make-col-modifier($modifier, $i, $columns)
          }
        }
      }
    }
  }
}

// Columns
//
// Common styles for small and large grid columns
@if $enable-grid-classes {
  @include make-grid-columns();
}
