/*
Scale
----------------------------------------------------------*/

/* 
Variables
------------------------*/
$timing: (
  scale: (
    entranceEasing    : cubic-bezier(0.165, 0.84, 0.44, 1),
    exitEasing        : cubic-bezier(0.165, 0.84, 0.44, 1),
    duration          : 300ms
  )
);


/*
Keyframes
----------------------------------------------------------*/

// Scale Up
@keyframes scale-up {
  0% {
    opacity: 0;
    transform: scale(0.75) translateX(0px) translateY(0px);
  }

  100% {
    opacity: 1;
    transform: scale(1) translateX(0px) translateY(0px);
  }
}

// Scale Down
@keyframes scale-down {
  0% {
    opacity: 1;
    transform: scale(1) translateX(0px) translateY(0px);
  }

  100% {
    opacity: 0;
    transform: scale(0.75) translateX(0px) translateY(0px);
  }  
}

/*
Animation Classes
----------------------------------------------------------*/

.scale-up {
  animation-name: scale-up;
  animation-duration: timingProp($timing, "scale", "duration");
  animation-timing-function: timingProp($timing, "scale", "entranceEasing");
}

.scale-down {
  animation-name: scale-down;
  animation-duration: timingProp($timing, "scale", "duration");
  animation-timing-function: timingProp($timing, "scale", "exitEasing");
}
