/*
Base checkbox styles
-----------------------------------------*/
$checkbox-size: 1rem;

input[type="checkbox"] {
    position:absolute;
    clip: rect(0,0,0,0);
    clip: rect(0 0 0 0);
}

input[type="checkbox"] + label::before {
  @extend %form-style;
  content: '';
  width: $checkbox-size;
  height: $checkbox-size;
  margin-right: $checkbox-size / 2;

}

input[type="checkbox"]:checked + label::before {
  content: '';
  background-color: #666;
}
