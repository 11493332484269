// ===================================================================================
// Type styles
// ------------
/* Good design relies on the consistent & intelligent use of a few type styles.
   Define this core set of type styles below, and use them with purpose throughout
   this interactive product.

   It may be a good idea to avoid including color in these mixins. Color largely depends on
   state, and therefore should be flexible (eg: link vs. title, active vs. visited, success vs. warning etc).
   For some projects though, you should include color below.  You'll need to use your expertise
   as a designer.
*/
$default-font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
$bold-font-family:"Montserrat", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;

@mixin header-1 {
  font-family: $bold-font-family;
  font-size: 2.857142857rem;
  font-weight: 700;
  line-height: 1.2;
}

@mixin header-2 {
  font-family: $bold-font-family;
  font-size: 1.523809524rem;
  font-weight: 700;
  line-height: 1.4;
}

@mixin header-3 {
  font-family: $bold-font-family;
  font-size: 1rem;
  font-weight: 900;
  line-height: 1.2;
}

@mixin label {
  font-family: $bold-font-family;
  font-size: 0.761904762rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
}

@mixin small {
  font-size: 0.761904762rem;
}

@mixin body-copy-large {
  font-family: $default-font-family;
  font-size: 1.333333333rem;
  font-weight: 300;
  line-height: $base-line-height;
}

@mixin body-copy {
  font-family: $default-font-family;
  font-size: 1rem;
  font-weight: 400;
  line-height: $base-line-height;
}


/*
Font Style Mixins
------------------------------------------*/
@mixin black-font {
  font-family: $default-font-family;
  font-weight: 900;
}

@mixin bold-font {
  font-family: $default-font-family;
  font-weight: 700;
}

@mixin reg-font {
  font-family: $default-font-family;
  font-weight: 400;
}

@mixin light-font {
  font-family: $default-font-family;
  font-weight: 300;
}

@mixin ex-light-font {
  font-family: $default-font-family;
  font-weight: 200;
}


