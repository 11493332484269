/* 
NavBar Component
---------------------------
@description: 
------------------------------------------------*/

.NavBar {
  width: 100%;
  position: fixed;
  z-index: z(nav);
  background-color: color(base,light);
  transition: background-color $base-duration $base-timing;

  @include media-breakpoint-up(lg) {
    background-color: transparent;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: $base-box-shadow;
    opacity: 0;
    z-index: -1;
    transition: opacity $base-duration $base-timing;
  }
}

/*
Feature Area Hidden
---------------------------------------*/
.featureArea--hidden .Navbar {
  background-color: color(base,light);
}

.featureArea--hidden .NavBar:after {
  opacity: 1;
  transition: opacity $base-duration;
}

// Homepage Hero
.NavBar.hide--nav {
  transform: translateY(-100%);
}

.NavBar-wrapper {
  //@include make-container();
  width: 100%;
  position: relative;
}

/*
** NavBar Logo
----------------------------------------------*/
.NavBar-anchor {
  display: block;
  float: left;
  overflow: hidden;
  margin-top: $base-spacing / 3;
  margin-left: $base-spacing / 2;
  margin-bottom: $base-spacing / 3;
  cursor: pointer;
  z-index: z(anchor);
  
  @include media-breakpoint-up(lg) {
    position: absolute;
    top: 0;
    left: 0;
    width: 170px;
    height: 170px;
    margin-top: $base-spacing / 2;
    opacity: 1;
    transition: opacity $base-duration $base-timing,
                height 175ms $base-timing;
  }
}

.NavBar-anchor .NavBar-logo--full {
  display: none;

  @include media-breakpoint-up(lg) {
    display: block;
    width: 170px;
    transition: transform $base-duration $base-timing;  
  }
}

.NavBar-anchor .NavBar-logo {
  width: 130px;
  transition: transform $base-duration $base-timing;

  @include media-breakpoint-up(lg) {
    opacity: 0;
  }
}

/*
Feature Area Hidden
---------------------------------------------*/
.featureArea--hidden .NavBar-anchor {
  
  @include media-breakpoint-up(lg) {
    margin-top: 0;
    height: 73px;
  }
}

.featureArea--hidden .NavBar-logo--full {
  @include media-breakpoint-up(lg) {
    opacity: 0;
    transform: translateY(-202px);
  }
}

.featureArea--hidden .NavBar-logo {

  @include media-breakpoint-up(lg) {
    transform: translateY(-154px);
    opacity: 1;
  }
}

/*
** Global Site Navigation
----------------------------------------------*/

.NavBar .NavBar-menuWrapper {
  float: none;
  clear: both;
  transition: padding-top $base-duration $base-timing,
              max-height $base-duration $base-timing;
  
  @include media-breakpoint-up(lg) {
    position: relative;
    padding-top: 82px;
    transition: padding-top $base-duration $base-timing;
  }
}

/*
Feature Area Hidden
---------------------------------------*/

.featureArea--hidden .NavBar .NavBar-menuWrapper {
  padding-top: 0;
}

.NavBar .NavBar-menu {
  position: relative;
  margin: 0 auto;
  overflow: visible;
  padding: 0  0 $base-spacing;
  text-align: center;
  
  @include media-breakpoint-up(lg) {
    display: block;
    margin: 0;
    padding: 0;
  }
}

/*
** Global Site Navigation Link
----------------------------------------------*/
.NavBar .NavBar-item {
  display: block;
  font-size: $base-font-size * 2;
  font-family: $bold-font-family;
  //text-align: center;

  @include media-breakpoint-up(lg) {
    position: relative;
    display: inline-block;
    font-size: $base-font-size;
    text-decoration: none;
    background: color(base,grey);
    box-shadow: inset 0px 3px 0px 0px rgba(18, 127, 63, 0);
    transition: box-shadow $base-duration $base-timing;
  }

  &:last-child {
    margin-right: 0;
  }
}

.NavBar-item.active {
  @include media-breakpoint-up(lg) {
    box-shadow: inset 0px 3px 0px 0px rgba(18, 127, 63, 1);
  }
}

.NavBar .NavBar-link {
  @include bold-font;
  display: block;
  padding: $base-spacing / 4 $base-spacing * 1.5;
  //color: color(base,dark);
  opacity: 0;
  transition: opacity $base-duration $base-timing $mediumDuration,
              color $base-duration $base-timing;

  @include media-breakpoint-up(lg) {
    display: inline-block;
    padding: $small-spacing;
    color: color(base,light);
    opacity: 1;
  }
}

/*
Feature Block Hidden
------------------------------------*/
.featureArea--hidden .NavBar .NavBar-link {
  color: color(brand,green);
}

/*
** Mobile Navigation State
----------------------------------------------------------------*/

// Mobile toggle Button
.NavBar .NavBar-mobileToggle {
  display: block;
  position: relative;
  float: right;
  overflow: hidden;
  padding: 0;
  width: 26px;
  height: 20px;
  margin-top: 27px;
  margin-right: $base-spacing / 2;
  font-size: 0;
  text-indent: -9999px;
  appearance: none;
  box-shadow: none;
  border-radius: none;
  border: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.NavBar .NavBar-mobileToggle span {
  display: block;
  position: absolute;
  top: 9px;
  left: 0px;
  right: 0px;
  height: 2px;
  background: color(brand,green);
  transition: background $base-duration 0.3s;
}

.NavBar .NavBar-mobileToggle span::before,
.NavBar .NavBar-mobileToggle span::after {
  content: "";
  position: absolute;
  display: block;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: color(brand,green);
  transition-duration: $base-duration, $base-duration;
  transition-delay: $base-duration, 0s;
}

.NavBar .NavBar-mobileToggle span::before {
  top: -9px;
  transition-property: top, transform;
}

.NavBar .NavBar-mobileToggle span::after {
  bottom: -9px;
  transition-property: bottom, transform;
}

/*
Mask
--------------------------------------------*/
.mask {
  transition: opacity 300ms;
  background: rgba(0,0,0, .5);
  visibility: hidden;
  position: fixed;
  opacity: 0;
  z-index: 2;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;

  @include media-breakpoint-up(lg) {
    display: none !important;
    opacity: 0 !important;
  }
}

.js-nav-active .mask {
  visibility: visible;
  opacity: 1;
}

.js .nav-collapse {
  clip: rect(0 0 0 0);
  max-height: 0;
  //position: absolute;
  display: block;
  overflow: hidden;
  zoom: 1;

  @include media-breakpoint-up(lg) {
    position: relative;
  }
}

.nav-collapse.closed {
  @include media-breakpoint-up(lg) {
    max-height: none;
  }
}

.nav-collapse.opened {
  max-height: 9999px;
}

.nav-toggle {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;

  @include media-breakpoint-up(lg) {
    display: none;
  }
}

/*
** Active state, i.e. menu open
---------------------------------------------*/
.NavBar.menu--open {
  
}

.NavBar.menu--open .NavBar-wrapper {
  
}

.NavBar.menu--open .NavBar-menuWrapper {

}

.js-nav-active .NavBar-link {
  opacity: 1;
  transition: opacity $base-duration $base-timing;
}

.NavBar.menu--open .NavBar-menu {
  
}

// Mobile Toggle Icon
.js-nav-active .NavBar-mobileToggle span {
  background: none;
}

.js-nav-active .NavBar-mobileToggle span::before {
  top: -2px;
  transform: rotate(45deg);
}

.js-nav-active .NavBar-mobileToggle span::after {
  bottom: 2px;
  transform: rotate(-45deg);
}

.js-nav-active .NavBar-mobileToggle span::before,
.js-nav-active .NavBar-mobileToggle span::after {
  background-color: color(brand,green);
  transition-delay: 0s, 0.3s;
}