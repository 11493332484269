/* 
Spacing
---------------------------
@description: Special classes that are used for spacing
--------------------------------------------------------*/

/* 
Spacing Variables
----------------------------*/
$spacer:      $base-spacing;
$spacer-x:    $base-spacing;
$spacer-y:    $base-spacing;

/* 
Multiplier Variables
----------------------------*/
$multiplier-medium:    1.5;
$multiplier-large:     3;

/* 
Margin
----------------------------*/
.m-a-0 { margin:        0 !important; }
.m-t-0 { margin-top:    0 !important; }
.m-r-0 { margin-right:  0 !important; }
.m-b-0 { margin-bottom: 0 !important; }
.m-l-0 { margin-left:   0 !important; }
.m-x-0 { margin-right:  0 !important; margin-left:   0 !important; }
.m-y-0 { margin-top:    0 !important; margin-bottom: 0 !important; }

.m-a { margin:        $spacer !important; }
.m-t { margin-top:    $spacer-y !important; }
.m-r { margin-right:  $spacer-x !important; }
.m-b { margin-bottom: $spacer-y !important; }
.m-l { margin-left:   $spacer-x !important; }
.m-x { margin-right:  $spacer-x !important; margin-left: $spacer-x !important; }
.m-y { margin-top:    $spacer-y !important; margin-bottom: $spacer-y !important; }
.m-x-auto { margin-right: auto !important; margin-left: auto !important; }

.m-a-md { margin:        ($spacer * $multiplier-medium) !important; }
.m-t-md { margin-top:    ($spacer-y * $multiplier-medium) !important; }
.m-r-md { margin-right:  ($spacer-y * $multiplier-medium) !important; }
.m-b-md { margin-bottom: ($spacer-y * $multiplier-medium) !important; }
.m-l-md { margin-left:   ($spacer-y * $multiplier-medium) !important; }
.m-x-md { margin-right:  ($spacer-x * $multiplier-medium) !important; margin-left:   ($spacer-x * $multiplier-medium) !important; }
.m-y-md { margin-top:    ($spacer-y * $multiplier-medium) !important; margin-bottom: ($spacer-y * $multiplier-medium) !important; }

.m-a-lg { margin:        ($spacer * $multiplier-large) !important; }
.m-t-lg { margin-top:    ($spacer-y * $multiplier-large) !important; }
.m-r-lg { margin-right:  ($spacer-y * $multiplier-large) !important; }
.m-b-lg { margin-bottom: ($spacer-y * $multiplier-large) !important; }
.m-l-lg { margin-left:   ($spacer-y * $multiplier-large) !important; }
.m-x-lg { margin-right:  ($spacer-x * $multiplier-large) !important; margin-left:   ($spacer-x * $multiplier-large) !important; }
.m-y-lg { margin-top:    ($spacer-y * $multiplier-large) !important; margin-bottom: ($spacer-y * $multiplier-large) !important; }

/* 
Padding
----------------------------*/
.p-a-0 { padding:        0 !important; }
.p-t-0 { padding-top:    0 !important; }
.p-r-0 { padding-right:  0 !important; }
.p-b-0 { padding-bottom: 0 !important; }
.p-l-0 { padding-left:   0 !important; }
.p-x-0 { padding-left:   0 !important; padding-right: 0 !important; }
.p-y-0 { padding-top:    0 !important; padding-bottom: 0 !important; }

.p-a { padding:        $spacer !important; }
.p-t { padding-top:    $spacer-y !important; }
.p-r { padding-right:  $spacer-x !important; }
.p-b { padding-bottom: $spacer-y !important; }
.p-l { padding-left:   $spacer-x !important; }
.p-x { padding-right:  $spacer-x !important; padding-left:   $spacer-x !important; }
.p-y { padding-top:    $spacer-y !important; padding-bottom: $spacer-y !important; }

.p-a-md { padding:        ($spacer * $multiplier-medium) !important; }
.p-t-md { padding-top:    ($spacer-y * $multiplier-medium) !important; }
.p-r-md { padding-right:  ($spacer-y * $multiplier-medium) !important; }
.p-b-md { padding-bottom: ($spacer-y * $multiplier-medium) !important; }
.p-l-md { padding-left:   ($spacer-y * $multiplier-medium) !important; }
.p-x-md { padding-right:  ($spacer-x * $multiplier-medium) !important; padding-left:   ($spacer-x * $multiplier-medium) !important; }
.p-y-md { padding-top:    ($spacer-y * $multiplier-medium) !important; padding-bottom: ($spacer-y * $multiplier-medium) !important; }

.p-a-lg { padding:        ($spacer * $multiplier-large) !important; }
.p-t-lg { padding-top:    ($spacer-y * $multiplier-large) !important; }
.p-r-lg { padding-right:  ($spacer-y * $multiplier-large) !important; }
.p-b-lg { padding-bottom: ($spacer-y * $multiplier-large) !important; }
.p-l-lg { padding-left:   ($spacer-y * $multiplier-large) !important; }
.p-x-lg { padding-right:  ($spacer-x * $multiplier-large) !important; padding-left:   ($spacer-x * $multiplier-large) !important; }
.p-y-lg { padding-top:    ($spacer-y * $multiplier-large) !important; padding-bottom: ($spacer-y * $multiplier-large) !important; }
