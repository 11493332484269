/*
Headings
--------------
@description: Base styles for heading elements
-----------------------------------------------------------------------------*/

h1,.h1 {
  @include header-1;
  margin: 0 0 $small-spacing;
}

h2,.h2 {
  @include header-2;
  margin: 0 0 $small-spacing;
}

h3,.h3 {
  @include header-3;
  margin: 0 0 $small-spacing;
}

// only user h4-h6 selectively.  h5, // Most apps don't need 6 headings; 3 should be sufficient
h4,h5,h6 {
  @include header-3;
  margin: 0 0 $small-spacing;
}

/* Section Labels are primarily used as titles on each homepage section  */
.sectionLabel {
  @include label;
  margin-bottom: 1rem;
  color: color(brand,primary);
}
