/* 
Updates List Component
---------------------------
@description: 
------------------------------------------------*/

// Updates List

.UpdatesList {
  @include make-container();
  max-width: 800px;
  padding-top: $base-spacing;
  padding-bottom: $base-spacing;

  @include media-breakpoint-up(md) {
    padding-top: $section-spacing / 2;
    padding-bottom: $section-spacing / 2;
  }
}

.UpdatesList__count {
  @include small;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.UpdatesList__item {
  border-bottom: 1px solid color(grey,light);
}

.UpdatesList__item {
  padding: $base-spacing / 1.5 0;
}

.UpdateList__item__date {
  @include small;
  @include bold-font;
  margin-bottom: $small-spacing / 2;
  letter-spacing: 0.5px;
}

.flexbox .UpdatesList__item__inner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.UpdatesList__item__link {
  display: block;
  width: 70%;
  margin-right: $base-spacing / 2;
  color: color(grey,dark);

  &:hover {
    color: color(brand,green);
  }
}

.UpdatesList__item__title {
  @include light-font;
  margin-bottom: 0;
}