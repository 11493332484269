/*
Base text input styles
-----------------------------------------*/


/*
Variables
------------------------------------*/

// Form Colors
$form-input-background-color:     color(base,light) !default;
$form-input-border:               2px solid $base-border-color !default;
// Form Shadows
$form-box-shadow:                  none !default; // eg: inset 0 1px 3px rgba(#000, 0.06);
$form-box-shadow-focus:            none !default; //eg: $form-box-shadow, 0 0 5px adjust-color($action-color, $lightness: -5%, $alpha: -0.3);
// A better designed select element (dropdows)
$prettify-select-dropdown:         true;
// CSS selectors - inputs
$all-text-inputs: 'input[type=text],
                   input[type=password],
                   input[type=search],
                   input[type=email],
                   input[type=url],
                   input[type=tel],
                   input[type=date],
                   input[type=number],
                   input[type=time],
                   textarea' !default;

/*
Field Groups
------------------------------------*/
fieldset {
  //background-color: $secondary-background-color;
  border: $base-border;
  margin: 0 0 $small-spacing;
  padding: $base-spacing;
}

input,
label,
select {
  display: block;
  font-size: $base-font-size;
}

label {
  font-weight: 600;
  margin-bottom: $small-spacing / 2;

  &.required::after {
    content: "*";
  }

  abbr {
    display: none;
  }
}

/*
Text Inputs
------------------------------------*/
#{$all-text-inputs} {
  @extend %form-style;
}

textarea {
  resize: vertical;
}

input[type="search"] {
  appearance: none;
}
