/* 
Footer Component
---------------------------
@description: 
------------------------------------------------*/

.Footer {
  text-align: center;
  color: color(base,light);
  background-color: color(base,dark);
}

.Footer__content {
  @include make-container();
  padding-top: $base-spacing;
  padding-bottom: $base-spacing;

  @include media-breakpoint-up(md) {
    padding-top: $section-spacing;
  }
}

.Footer__logo {
  width: 8.333333333rem;
}

.Footer__name {
  @include reg-font;
  margin-top: $small-spacing;
  text-transform: uppercase;
  letter-spacing: 0.75px;
}

.Footer__tagline {
  margin-bottom: $base-spacing;
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 1.25px;
}

.Footer__contact {
  @include small;
  @include light-font;
  color: color(base,light);
  letter-spacing: 0.75px;
}

.Footer__contact strong {
  @include bold-font;
  letter-spacing: 1.25px;
}

.Footer__contact__link {
  color: color(base,light);
}

.Footer__copyright {
  @include small;
  color: color(base,light);
}
